import { FunctionComponent, useEffect } from 'react';
import { Spinner, validateInput } from '../../components';
import styles from './ChangeUsernamePage.module.scss';
import { useGetUserQuery, useSetUsernameMutation } from './ChangeUsername.graphql';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import style from '../../components/Layout.module.scss';

export const ChangeUsernamePage: FunctionComponent = () => {
	const [setUsername] = useSetUsernameMutation();
	const { data, loading } = useGetUserQuery({
		fetchPolicy: 'no-cache',
	});
	const navigate = useNavigate();
	const { register, handleSubmit, setValue } = useForm<{
		username: string;
	}>({
		defaultValues: {
			username: '',
		},
	});

	useEffect(() => {
		if (data?.myUser?.username) {
			setValue('username', data.myUser.username);
		}
	}, [data]);

	const onSubmit = handleSubmit(async data => {
		const response = await setUsername({
			variables: {
				username: data.username,
			},
		});

		if (response.data?.setUsername.message) {
			alert(response.data?.setUsername.message);
		}

		if (!response.data?.setUsername.success) {
			return;
		}

		navigate('/h5/choose');
	});

	if (loading) {
		return <Spinner center />;
	}

	return (
		<div className={styles.content}>
			<form onSubmit={onSubmit}>
				<br />
				<br />
				<div className="logo">
					<img src="/images/translations/logo_dk.png" alt="Hundeparken logo" />
				</div>
				<div className={styles.window}>
					<h1>Edit user</h1>
					Username
					<input
						type="text"
						id="username"
						{...register('username', {
							onBlur: e => validateInput(e.target as HTMLInputElement, true),
							onChange: e => validateInput(e.target as HTMLInputElement),
						})}
						required
					/>
					<br />
					<button type="submit">OK</button>
				</div>
			</form>
			<div className={style.navigatorOuterContainer}>
				<div className={style.navigatorContainer}>
					<Link to="/h5/choose">
						<button className={style.arrow} />
					</Link>
				</div>
			</div>
		</div>
	);
};
