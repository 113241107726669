import { Fragment, FunctionComponent, useEffect } from 'react';
import style from './RainbowBox.module.scss';
import { useGetMyDogQuery, useLoginMutation } from './Rainbow.graphql';
import { Platform, Spinner, loadScript, useApp, useLanguageContext } from '..';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_TOKEN_NAME } from '../../constants';

/*
 https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid#get_your_google_api_client_id

 Følg punkt 4 med at adde det til API'en

*/

export const RainbowBox: FunctionComponent = () => {
	const { inlineTranslation, language } = useLanguageContext();
	const { data, error, refetch, loading } = useGetMyDogQuery();
	const { isApp, createAppswitchUrl } = useApp();
	const [login] = useLoginMutation();
	const navigate = useNavigate();

	const signedIn = data?.randomDog && !error;

	const signOut = () => {
		localStorage.removeItem(AUTH_TOKEN_NAME);
		refetch();
	};

	useEffect(() => {
		(window as any).newLogin = async (data: { credential: string } | undefined) => {
			if (data) {
				const response = await login({ variables: { token: data.credential, language } });
				const loginResponse = response.data?.googleLogin;
				if (loginResponse) {
					if (loginResponse.newUser) {
						localStorage.setItem('id_token', data.credential);
						navigate(`/h5/new-user`);
					} else {
						localStorage.setItem(AUTH_TOKEN_NAME, loginResponse.token);
						refetch();
					}
				}
			}
		};
	});

	useEffect(() => {
		if (signedIn && isApp) {
			window.location.href = createAppswitchUrl({ success: '1', token: localStorage.getItem(AUTH_TOKEN_NAME) || '' });
		}
	}, [signedIn]);

	useEffect(() => {
		if (!signedIn && !loading) {
			loadScript('https://accounts.google.com/gsi/client');
		}
	}, [signedIn, loading]);
	return (
		<>
			<div>
				<div className={style.container}>
					{data?.randomDog && !error ? (
						<div className={style.signedIn}>
							<Link to="/h5/choose">
								<div className={style.toPark}>
									<Platform
										className={style.dog}
										color={data.randomDog.color}
										shadowColor={data.randomDog.shadowColor}
										dogName={data.randomDog.name}
										flipped
									/>
									<div className={style.toParkBtn}>
										{inlineTranslation({
											dk: (
												<Fragment>
													Gå til
													<br />
													parken
												</Fragment>
											),
											no: (
												<Fragment>
													Gå til
													<br />
													parken
												</Fragment>
											),
											se: (
												<Fragment>
													Gå till
													<br />
													parken
												</Fragment>
											),
											en: (
												<Fragment>
													Enter
													<br />
													the park
												</Fragment>
											),
										})}
									</div>
								</div>
							</Link>
							<div className={style.signOut} onClick={signOut}>
								{inlineTranslation({
									dk: (
										<Fragment>
											Log mig ud,
											<br />
											tak!
										</Fragment>
									),
									no: (
										<Fragment>
											Logg meg ut,
											<br />
											takk!
										</Fragment>
									),
									se: (
										<Fragment>
											Logga ut mig,
											<br />
											tack!
										</Fragment>
									),
									en: (
										<Fragment>
											Sign me out,
											<br />
											Please!
										</Fragment>
									),
								})}
							</div>
						</div>
					) : (
						<div>
							<div className={style.sampleDisplay}>
								<div className={style.sampleGoogleButton}>
									<Spinner />
								</div>
								<div key="test">
									<div
										className="g_id_signin"
										data-type="standard"
										data-shape="rectangular"
										data-theme="outline"
										data-text="signin_with"
										data-size="large"
										data-logo_alignment="left"
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div
				id="g_id_onload"
				data-client_id="892677975321-k0bp09ni0jcfiupharbknn01e15le5te.apps.googleusercontent.com"
				data-context="signin"
				data-ux_mode="popup"
				data-callback="newLogin"
				data-auto_select="false"
			/>
		</>
	);
};
