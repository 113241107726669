import { FunctionComponent } from 'react';

import style from './AnnouncementsBox.module.scss';
import { useGetLatestAnnouncementsQuery } from './LatestAnnouncements.graphql';
import { useLanguageContext } from '..';
import { Link } from 'react-router-dom';
import { GlitchyText } from './GlitchSymbol';

export const AnnouncementsBox: FunctionComponent = () => {
	const { inlineTranslation } = useLanguageContext();
	const { data } = useGetLatestAnnouncementsQuery({
		fetchPolicy: 'no-cache',
	});

	const announcements = data?.latestAnnouncements ?? [];
	return (
		<div className={style.introduction}>
			<div className={style.title}>
				{inlineTranslation({
					dk: 'Nyheder & udvikling',
					no: 'Nyheter & utvikling',
					se: 'Nyheter & utveckling',
					en: 'News & Development',
				})}
			</div>
			<div className={style.container}>
				<div className={style.body}>
					{announcements.map(announcement => (
						<div className={style.announcement} key={announcement.id}>
							<div className={style.announcementTitle}>
								{announcement.date}: <GlitchyText text={announcement.title || ''} />
							</div>

							<GlitchyText text={announcement.message} />
							<br />
							<Link to={`/announcement/${announcement.id}`}>
								{inlineTranslation({
									dk: 'Læs mere',
									no: 'Les mer',
									se: 'Läs mer',
									en: 'Read more',
								})}
								{' »'}
							</Link>
						</div>
					))}
				</div>
			</div>
			<div className={style.speechTriangle} />
			<img src="images/developer_computer.gif" alt="" />
		</div>
	);
};
