import classNames from 'classnames';
import { FunctionComponent, useRef, useEffect } from 'react';
import styles from './Platform.module.scss';
import { IS_DEV } from '../../paths';

enum MorphTypes {
	DOG = 'dog',
	CAT = 'cat',
	WOLF = 'wolf',
	BEAR = 'bear',
}

interface ColorOptions {
	r: number;
	g: number;
	b: number;
	shadowStrength?: number;
}

export interface NewPlatformOptions {
	id?: number;
	hat: number;
	back: number;
	mouth: number;
	ass?: number;
	dogname?: string;

	color: number;
	shadowcolor: number;
	morphStyle?: MorphTypes | null;
}

declare global {
	interface Window {
		makePlatform?: (
			target: HTMLElement,
			data: NewPlatformOptions,
			flipped: boolean
		) => Promise<{
			hover: (doWag: boolean) => void;
			cleanUp: () => void;
			chat: (message: string) => void;
			setColor: (options: ColorOptions) => {
				shadowColor: number;
				color: number;
				chatColor: number;
			};
		}>;
	}
}

type PlatformData = Awaited<ReturnType<NonNullable<(typeof window)['makePlatform']>>>;

export function usePlatform(options: NewPlatformOptions) {
	const divRef = useRef<HTMLDivElement>(null);
	const platformOptions = useRef<PlatformData>();

	useEffect(() => {
		if (window.makePlatform && divRef.current) {
			let destroyed = false;
			window.makePlatform(divRef.current, options, false).then(platform => {
				if (destroyed) {
					platform.cleanUp();
				} else {
					platformOptions.current = platform;
					platform.chat('Hundeparken <3');
				}
			});

			return () => {
				platformOptions?.current?.cleanUp?.();
				destroyed = true;
			};
		}
	}, [window.makePlatform, divRef.current]);

	return {
		Platform: <div ref={divRef} />,
		setColor: (options: ColorOptions) => {
			return platformOptions?.current?.setColor(options);
		},
	};
}

export const Platform: FunctionComponent<{
	className?: string;
	wear?: { hat?: number; back?: number; mouth?: number; ass?: number };
	color: number;
	shadowColor: number;
	dogName?: string;
	isDead?: boolean;
	flipped?: boolean;

	hasPodium?: boolean;
	hasGrassPodium?: boolean;
}> = props => {
	const divRef = useRef(null);
	const hoverFunc = useRef<PlatformData['hover']>();

	useEffect(() => {
		if (divRef.current && window.makePlatform && !props.isDead) {
			let cleanUp: PlatformData['cleanUp'] | undefined;
			let destroyed = false;
			window
				.makePlatform(
					divRef.current,
					{
						back: props.wear?.back ?? 0,
						hat: props.wear?.hat ?? 0,
						mouth: props.wear?.mouth ?? 0,
						ass: props.wear?.ass ?? 0,
						color: props.color,
						shadowcolor: props.shadowColor,
						dogname: props.dogName,
					},
					props.flipped || false
				)
				.then(platform => {
					if (destroyed) {
						platform.cleanUp();
					} else {
						cleanUp = platform.cleanUp;
						hoverFunc.current = platform.hover;
					}
				});

			return () => {
				cleanUp?.();
				destroyed = true;
			};
		}
	}, [divRef, window.makePlatform]);

	return (
		<div
			onPointerOver={() => hoverFunc.current?.(true)}
			onPointerOut={() => hoverFunc.current?.(false)}
			className={classNames(props.className, {
				[styles.podium]: props.hasPodium,
				[styles.grassPodium]: props.hasGrassPodium,
				[styles.platform]: !props.hasPodium,
			})}>
			{IS_DEV || props.isDead ? (
				<div>
					{props.isDead ? (
						<img src={require('../../images/platform/gravestone.png')} alt="" style={{ marginTop: '-15px' }} />
					) : (
						<img src={require('./dummy.png')} alt="" style={props.flipped ? { transform: 'scaleX(-1)' } : {}} />
					)}
				</div>
			) : (
				<div ref={divRef} />
			)}
		</div>
	);
};
