import { FunctionComponent, useState } from 'react';
import style from './Gallery.module.scss';

export const Gallery: FunctionComponent<{ images: string[] }> = props => {
	const [currentSlide, setSlide] = useState(0);

	return (
		<div className={style.container}>
			<button onClick={() => setSlide(currentSlide === 0 ? props.images.length - 1 : currentSlide - 1)}>
				<img src="images/double_arrow_left.png" alt="arrow left" />
			</button>
			<div className={style.frame}>
				<img src={`/images/snapshot/${props.images[currentSlide]}`} alt="Gallery slide" />
			</div>
			<button onClick={() => setSlide((currentSlide + 1) % props.images.length)}>
				<img src="images/double_arrow_right.png" alt="arrow right" />
			</button>
		</div>
	);
};
