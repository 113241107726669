import { FunctionComponent } from 'react';
import style from './Spinner.module.scss';
import classNames from 'classnames';

export const Spinner: FunctionComponent<{ center?: boolean }> = props => {
	return (
		<div
			className={classNames({
				[style.centerSpinner]: props.center,
			})}>
			<div className={style.loader} />
		</div>
	);
};
