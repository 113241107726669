import { Fragment, FunctionComponent, useEffect, useRef } from 'react';
import styles from './Glitch.module.scss';

export const GLITCH_SYMBOL = '[%_]';
const glitchLetters = 'QWERTYUIOPÅASDFGHJKLÆØZXCVBNM@£$€{[]}123456789/*-+,.<>\\'.split('');

const GlitchSymbol: FunctionComponent<{ baseText: string }> = props => {
	const ref = useRef<HTMLDivElement>(null);

	const setSymbol = () => {
		if (!ref.current) {
			return;
		}
		const replacementLetter = glitchLetters[Math.floor(Math.random() * glitchLetters.length)];
		ref.current.innerText = replacementLetter;
	};

	useEffect(() => {
		const interval = setInterval(setSymbol, 200);
		return () => clearInterval(interval);
	});

	return (
		<>
			<p dangerouslySetInnerHTML={{ __html: props.baseText }} className={styles.glitchSymbol} />
			<p ref={ref} className={styles.glitchSymbol} />
		</>
	);
};

export const GlitchyText: FunctionComponent<{ text: string }> = props => {
	const messageLines = props.text.split('\n').map(line => line.split(GLITCH_SYMBOL));
	return (
		<>
			{messageLines.map((line, i) => {
				return (
					<Fragment key={i}>
						{line.map((symbols, i) => (
							<Fragment key={i}>
								{i > 0 ? <GlitchSymbol baseText={symbols} /> : <p dangerouslySetInnerHTML={{ __html: symbols }} />}
							</Fragment>
						))}
					</Fragment>
				);
			})}
		</>
	);
};
