import { FunctionComponent, useEffect, useState } from 'react';
import { useMyDogsQuery } from './Choose.graphql';
import { Platform, RetroButton, useApp } from '../../components';
import styles from './Choose.module.scss';
import platformStyle from '../../components/Platform/Platform.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_TOKEN_NAME } from '../../constants';

export const ChoosePage: FunctionComponent = () => {
	const { isApp, sendAppEvent } = useApp();
	const [canKill, setCanKill] = useState(false);
	// const { data: patreonData } = usePatreonStatusQuery();
	const { data, loading } = useMyDogsQuery({
		fetchPolicy: 'no-cache',
	});
	const navigate = useNavigate();
	const dogs = data?.myUser?.dogs ?? [];

	useEffect(() => {
		if (data?.myUser === null && !loading) {
			sendAppEvent('sign-out');
			navigate('/');
		}
	}, [data?.myUser, loading]);

	useEffect(() => {
		if (data?.myUser?.dogs && data?.myUser?.dogs.length === 0 && !loading) {
			(window as any).location = '/h5/choose/new';
		}
	}, [data, loading]);

	const freeSpots = Math.max((data?.myUser?.maxDogs ?? 0) - dogs.length, 0);

	return (
		<div>
			<div className={styles.topBar}>
				<div className={styles.topBarContainer}>
					<RetroButton
						color="pink"
						onClick={() => {
							localStorage.removeItem(AUTH_TOKEN_NAME);
							window.location.reload();
						}}>
						LOG OUT
					</RetroButton>
					<RetroButton color="red" onClick={() => setCanKill(canKill => !canKill)}>
						KILL
					</RetroButton>
					{data?.myUser && (
						<RetroButton
							color="patreon-orange"
							onClick={() => window.location.assign('/api/patreon/auth')}
							style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
							className="text-white">
							<img src="/images/patreon/logo.svg" height="12px" alt="patreon logo" />
							<p className="py-10">Connect with Patreon</p>
						</RetroButton>
					)}
				</div>
				<Link to="/h5/change-username">
					<RetroButton color="blue" style={{ justifySelf: 'flex-end' }}>
						{data?.myUser?.username}
					</RetroButton>
				</Link>
				{/* 
				{patreonData?.patreonSubscription?.isActive === true && (
					<RetroButton color="blue" onClick={patronAuth}>
						{patreonData.patreonSubscription.tier}
					</RetroButton>
				)} */}
			</div>
			{data?.myUser?.activeBan && (
				<div className={styles.banMessage}>
					<b>Banned until {data.myUser.activeBan?.endsAt} GMT</b>
					<br />
					Reason: {data.myUser.activeBan?.reason}
					<br />
					Do not attempt to login to another user account, this will double the ban time.
				</div>
			)}
			<div className={styles.content}>
				<img src="/images/translations/logo_dk.png" alt="Hundeparken logo" />
				<div className={styles.container}>
					{dogs.map(dog => (
						<Link
							to={dog.isDead ? `/h5/choose/new/${dog.id}` : isApp ? '/h5/choose' : `/h5/client?id=${dog.id}`}
							onClick={() => {
								if (isApp) {
									sendAppEvent('choose', { id: dog.id });
								}
							}}
							reloadDocument
							key={dog.id}>
							<div className={styles.dog}>
								<Platform
									color={dog.color}
									shadowColor={dog.shadowColor}
									wear={dog.wear}
									isDead={dog.isDead}
									flipped={false}
									hasGrassPodium
								/>
								<div className={styles.name}>{dog.name}</div>
								{canKill && (
									<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
										<RetroButton
											color="red"
											onClick={e => {
												e.preventDefault();
												e.stopPropagation();
												navigate(`/h5/choose/new/${dog.id}`);
											}}>
											Rebirth
										</RetroButton>
									</div>
								)}
							</div>
						</Link>
					))}
					{Array.from({ length: freeSpots }, (_, i) => (
						<Link to={`/h5/choose/new`} reloadDocument key={i}>
							<div className={platformStyle.grassPodium} />
						</Link>
					))}
				</div>
			</div>
		</div>
	);
};
