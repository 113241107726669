import {
	InformationBox,
	TranslationKey,
	Platform,
	useLanguageContext,
	InformationBoxSectionColor,
	Spinner,
	InformationBoxSection,
} from '../components';
import { FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';
import creditStyles from './CreditsPage.module.scss';
import { useGetTeamsQuery } from './Credits.graphql';

const types = ['Designer', 'ClientDev', 'FestKomiteen', 'SocialMedia'] as const;

type Type = (typeof types)[number];
const teamData: Record<Type, { translation: TranslationKey; color: InformationBoxSectionColor }> = {
	Designer: {
		translation: 'CREDITS_DESIGNER',
		color: 'pink',
	},
	ClientDev: {
		translation: 'CREDITS_CLIENT_DEV',
		color: 'yellow',
	},
	FestKomiteen: {
		translation: 'CREDITS_PARTY_COMITEE',
		color: 'lightgreen',
	},
	SocialMedia: {
		translation: 'CREDITS_SOCIAL_MEDIA',
		color: 'lightpurple',
	},
};

export const CreditsPage: FunctionComponent = () => {
	const { data, loading } = useGetTeamsQuery({ variables: { types: [...types] } });
	const { getTranslation } = useLanguageContext();

	const dogs = data?.team ?? [];
	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					title={'Holdet bag hundeparken'}
					color="#d800ff"
					icon="images/medal.png"
					sections={[
						{
							content: getTranslation('CREDITS_INTRODUCTION'),
						},
						...(loading
							? [{ content: <Spinner /> }]
							: dogs.map((dog): InformationBoxSection => {
									const team = teamData[dog.role as Type];
									return {
										title: dog.name,
										titleColor: team?.color,
										content: (
											<div className={creditStyles.platformContainer}>
												<div>
													<Platform color={dog.color} hasPodium shadowColor={dog.shadowColor} wear={dog.wear} />
												</div>
												<div className={creditStyles.infoText}>
													<div>
														<b>Navn:</b> {dog.name} <b>Brukernavn:</b> {dog.username}
													</div>
													<div>
														<b>Bidrag:</b> {dog.role ? getTranslation(team.translation) : ''}
													</div>
												</div>
											</div>
										),
									};
								})),
					]}
				/>

				{/* Get all platforms from API based on bagde
				{sections.map(section => {
					return <TeamSection key={section.title} section={section} />;
				})} */}
			</div>
		</div>
	);
};
