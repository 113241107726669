import { NewPlatformOptions, Spinner, usePlatform } from '../../components';
import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMyDogsQuery } from './Choose.graphql';
import { useForm } from 'react-hook-form';

const ColorSliders: FunctionComponent<{
	setColor: ReturnType<typeof usePlatform>['setColor'];
	isMod: boolean;
}> = props => {
	const { register, watch } = useForm<{
		red: number;
		green: number;
		blue: number;
		shadowStrength: number;
	}>({
		defaultValues: {
			red: 0,
			green: 0,
			blue: 0,
			shadowStrength: 0.7,
		},
	});

	const data = watch();

	useEffect(() => {
		props.setColor({
			r: data.red,
			g: data.green,
			b: data.blue,
			shadowStrength: data.shadowStrength,
		});
	}, [data]);

	return (
		<>
			<div>
				R:
				<input
					type="range"
					step={1}
					min={0}
					max={255}
					{...register('red', { min: 0, max: 255, valueAsNumber: true })}
				/>
			</div>
			<div>
				G:
				<input
					type="range"
					step={1}
					min={0}
					max={255}
					{...register('green', { min: 0, max: 255, valueAsNumber: true })}
				/>
			</div>
			<div>
				B:
				<input
					type="range"
					step={1}
					min={0}
					max={255}
					{...register('blue', { min: 0, max: 255, valueAsNumber: true })}
				/>
			</div>
			{props.isMod && (
				<div>
					Shadow:
					<input
						type="range"
						step={0.05}
						min={0}
						max={1}
						{...register('shadowStrength', { valueAsNumber: true, min: 0, max: 1 })}
					/>
				</div>
			)}
		</>
	);
};

const DogColorSliders: FunctionComponent<{ options: NewPlatformOptions; isMod: boolean }> = props => {
	const { Platform, setColor } = usePlatform(props.options);

	return (
		<div style={{ background: '#FFF', padding: '20px' }}>
			{Platform}
			<ColorSliders isMod={props.isMod} setColor={setColor} />
		</div>
	);
};

export const DogColorPickerPage: FunctionComponent = () => {
	const { data, loading } = useMyDogsQuery({
		fetchPolicy: 'no-cache',
	});
	const isMagicBanana = new URL(document.URL).searchParams.get('magic') !== null;
	const { id } = useParams();

	const dog = data?.myUser?.dogs?.find(dog => dog.id === Number(id));

	if (loading) {
		return <Spinner />;
	}

	if (!dog) {
		return null;
	}

	return (
		<DogColorSliders
			isMod={isMagicBanana || data?.myUser?.admin !== 0 || data.myUser.moderator !== 0}
			options={{
				color: dog.color,
				shadowcolor: dog.shadowColor,
				back: dog.wear.back,
				hat: dog.wear.hat,
				mouth: dog.wear.mouth,
			}}
		/>
	);
};
