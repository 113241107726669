import { FunctionComponent } from 'react';

export const ModBox: FunctionComponent = () => {
	return (
		<div className="kick window">
			<table data-style="width: 100%">
				<tr>
					<td className="logo header">
						<img alt="" src="game/gfx/ui/kick/logo.png" />
					</td>
					<td className="close">
						<img alt="" src="game/gfx/ui/kick/close.png" className="closewindow" />
					</td>
				</tr>
			</table>
			<div className="body">
				<table>
					<tr>
						<td>Dogname</td>
						<td className="dogname"></td>
					</tr>
					<tr>
						<td>Ownername</td>
						<td className="ownername"></td>
					</tr>
					<tr>
						<td>UserID</td>
						<td className="userid"></td>
					</tr>
					<tr>
						<td>Ban duration</td>
						<td>
							<select className="banduration">
								<option value="0">Kick only</option>
								<option value="300">+5 minutes</option>
								<option value="1800">+30 minutes</option>
								<option value="7200">+2 hours</option>
								<option value="86400">+24 hours</option>
							</select>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<textarea className="reason"></textarea>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<button id="kickbtn">Kick dat mofo</button>
						</td>
					</tr>
				</table>
			</div>
		</div>
	);
};
