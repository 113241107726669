import { useLanguageContext } from '../../components';
import { FunctionComponent, useEffect } from 'react';
import styles from './WhatIs.module.scss';
import classNames from 'classnames';

export const RangPage: FunctionComponent = () => {
	const { inlineTranslation, language } = useLanguageContext();

	useEffect(() => {
		document.title = inlineTranslation({
			dk: 'RANG: Hvad er det?',
			no: 'RANG: Hva er det?',
			se: 'RANG: Vad är det?',
			en: 'RANK: What is it?',
		});

		document.body.dataset.grid = 'no';
	});

	const ranks = [
		{
			image: 'HVALP',
			title: inlineTranslation({
				dk: 'HVALPE',
				no: 'VALP',
				se: 'VALP',
				en: 'PUPPY',
			}),
			description: inlineTranslation({
				dk: 'Hvalpe er de nyfødte hunde i parken, de kan ikke så meget endnu - men optjener ret hurtigt Karma point. Hvalpe er nysgerrige af natur og bruger megen tid på at gå rundt og undre sig over, hvor de er.',
				no: 'Valper er de nyfødte hundene i parken, de kan ikke så mye enda - men de tjener raskt opp karmapoints. Valper er nysgjerrige av natur og bruker mye tid på å gå rundt og undre seg over hvor de er.',
				en: 'Valpar är de yngsta hundarna i parken. De kan inte göra så mycket ännu, men kan snabbt komma upp i höga karmapoäng. Valpar är av naturen nyfikna och spenderar mycket tid till att gå runt i parken och lära sig av de andra hundarna.',
				se: 'Puppies are the newborn dogs in the park, they can not do much yet - but earn Karma points pretty quickly. Puppies are curious by nature and spend a lot of time walking around and wondering where they are.',
			}),
		},
		{
			image: 'SLEADE',
			title: inlineTranslation({
				dk: 'SLÆDEHUNDE',
				no: 'SLEDEHUND',
				se: 'SLÄDHUND',
				en: 'SLED DOG',
			}),
			description: inlineTranslation({
				dk: 'Slædehundene er unge hurtige hunde, der lever livet i overhalingsbanen. De ynder at ride rundt på ryggen af andre hunde og hænger ellers ud i parken så hårdt de kan.',
				no: 'Sledehundene er unge og raske - Hundeparkens teenagere. De digger å ri på ryggen til andre hunder og henger ellers ut i parken.',
				se: 'Slädhundar är unga och starka hundar som verkligen lever livet. De hänger runt i parken så ofta de kan och älskar att rida runt på andra hundars ryggar.',
				en: 'Sled dogs are young fast dogs who live life in the overtaking lane. They like to ride around on the backs of other dogs and otherwise hang out in the park as hard as they can.',
			}),
		},
		{
			image: 'SPOR',
			title: inlineTranslation({
				dk: 'SPORHUNDE',
				no: 'SPORHUND',
				se: 'SPORTHUND',
				en: 'TRACK DOG',
			}),
			description: inlineTranslation({
				dk: 'Sporhunde elsker at gå på opdagelse og lægge spor hvor de har været i form af tis og lort. Hvis man vil vide noget om hvor ting er i Hundeparken så skal man bare spørge en sporhund.',
				no: 'Sporhunder elsker å gå på oppdagelsesferd og legge igjen spor om hvor de har vært i form av piss eller dritt. Hvis man vil vite noe om hvor ting er i Hundeparken er det bare å spørre en sporhund.',
				se: 'Sporthundar älskar att gå på upptäcktsfärd och lägga ut spår där de varit. Om du har svårt att hitta i parken är det bara att fråga en sporthund om hjälp.',
				en: 'Track dogs love to go exploring and leave traces where they have been in the form of pee and shit. If you want to know something about where things are in the park then you just have to ask a tracking dog.',
			}),
		},
		{
			image: 'VAND',
			title: inlineTranslation({
				dk: 'VANDHUND',
				no: 'VANNHUND',
				se: 'VATTENHUND',
				en: 'WATER DOG',
			}),
			description: inlineTranslation({
				dk: 'Vandhunde elsker at svømme og drive den af på stranden, hvor de kan spille lidt med musklerne og imponere hunde af det modsatte køn',
				no: 'Vannhunder elsker å svømme og sløve på stranda - der de kan spenne muskler og imponere hunder av det motsatte kjønn.',
				se: 'Vattenhundar älskar att simma, glassa omkring på stranden och samtidigt passa på att visa upp sina muskler för att imponera på de andra hundarna. ',
				en: 'Water dogs love to swim and drive it off on the beach where they can play a little with the muscles and impress dogs of the opposite sex',
			}),
		},
		{
			image: 'RUM',
			title: inlineTranslation({
				dk: 'RUMHUND',
				no: 'ROMHUND',
				se: 'RYMDHUND',
				en: 'SPACE DAG',
			}),
			description: inlineTranslation({
				dk: 'Rumhunde er hunde, der i en kort tid har haft et job som testpiloter i rumfærger. De kan godt li at kigge på stjerner - og drømmer om en planet langt langt væk, hvor gaderne flyder med kødben og farsbrød',
				no: 'Romhunder er hunder som i en periode har arbeidet som testpiloter i romferger. De liker godt å ligge å kikke på stjernene og drømme om en planet langt, langt vekk der gatene bugner av kjøttbein og brannhydranter.',
				se: 'Rymdhundar har under en kort period i sitt liv arbetat som testpilot på rymdskepp. De tycker om att ligga i gräset och titta på stjärnorna och drömma sig bort till andra planeter där gatorna är fulla med köttben och hundkex.',
				en: 'Space dogs are dogs that have for a short time had a job as test pilots in space shuttles. They like to look at stars - and dream of a planet far, far away, where the streets flow with meat bones.',
			}),
		},
		{
			image: 'GOEGLER',
			title: inlineTranslation({
				dk: 'GØGLERHUND',
				no: 'GJØGLERHUND',
				se: 'JYCKLARHUND',
				en: 'JOKER DOG',
			}),
			description: inlineTranslation({
				dk: 'Gøglerhunde elsker spas, ballade og forklædninger. Af og til sniger en gøglerhund sig rundt forklædt som en busk og overrasker andre hunde der er midt i en parring. Andre gange opfører de Hamlet eller mimer så godt de kan. De fleste andre hunde hader gøglerhunde.',
				no: 'Gjøglerhunder elsker forkledninger, skuespill og show. Av og til sniker en gjøglerhund seg rundt forkledt som en busk og overrasker andre hunder som parrer seg. Andre ganger resiterer de Hamlet eller har pantomimeshow. De fleste andre hunder hater gjøglerhundene.',
				se: 'Jycklarhundar älskar att uppträda, klä ut sig och busa med de andra hundarna. Till och från smyger de omkring i parken utklädda till buskar och spionerar på de andra hundarna. Vid andra tillfällen sätter de upp Hamlet eller mimar så gott de kan. För de mesta blir de andra hudarna irriterade på Jycklarhundarna. ',
				en: 'Joker dogs love fun, trouble and disguises. Occasionally a fun dog sneaks around disguised as a bush and surprises other dogs in the middle of a mating.',
			}),
		},
		{
			image: 'NINJA',
			title: inlineTranslation({
				dk: 'NINJAHUND',
				no: 'NINJAHUND',
				se: 'NINJAHUND',
				en: 'NINJAHUND',
			}),
			description: inlineTranslation({
				dk: 'Ninjahunde er i top form, og kan fosvinde på et splitsekund. Hunde i denne rang kender til de fleste kroge i parken, og vil nok kunne svare på alle dine spørgsmål.',
				no: 'Ninjahunder er i toppform, og kan forsvinne på et millisekund. Hunder i denne rangen kjenner de fleste kroker i parken, og vil sannsynligvis kunne svare på alle dine spørsmål.',
				se: 'Ninjahundar är i toppform och kan försvinna på en millisekund. Hundar i denna rang kan de flesta krokar i parken och kommer förmodligen att kunna svara på alla dina frågor.',
				en: 'Ninja dogs are in top shape, and can disappear in a split second. Dogs in this rank know most nooks and crannies in the park, and will probably be able to answer all your questions.',
			}),
		},
		{
			image: 'SECRET',
			title: inlineTranslation({
				dk: '???',
				no: '???',
				se: '???',
				en: '???',
			}),
			description: inlineTranslation({
				dk: ' ',
				no: ' ',
				se: ' ',
				en: ' ',
			}),
		},
	];

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img src={`/images/whatis/rang-${language}.png`} alt="Header" />
			</div>
			<div className={styles.info}>
				{inlineTranslation({
					dk: 'Hunde bliver klogere med tiden og lærer hele tiden nye tricks. Jo klogere man er - jo højere rang har man i hundeparken, f.eks kan man først skide når man er sporhund og svømme når man er blevet vandhund. Her er en oversigt over range:',
					no: 'Hunder blir klokere med tiden og lærer hele tiden nye triks. Jo klokere man er jo høyere rang har man i hundeparken. Her er en oversikt over rangordningen:',
					se: 'Hundar blir med tiden klokare och lär sig ständigt nya tricks. Desto klokare en hund är desto högre rang har hunden i Hundparken. Här är en översikt över Hundparkens rangsystem:',
					en: 'Hunder blir klokere med tiden og lærer hele tiden nye triks. Jo klokere man er jo høyere rang har man i hundeparken. Her er en oversikt over rangordningen:',
				})}
			</div>
			{ranks.map((rank, index) => (
				<div className={styles.rang} key={rank.image}>
					<div className={styles.img}>
						<img src={`/images/whatis/${rank.image}.png?3`} alt={rank.title} />
					</div>
					<div className={styles.desc}>
						<div className={styles.title}>
							{index + 1}: {rank.title}
						</div>
						{rank.description}
					</div>
				</div>
			))}

			<div className={classNames(styles.rang, styles.seperator)}>
				<div className={styles.img}>
					<img src="/images/whatis/admin.png" alt="" />
				</div>
				<div className={styles.desc}>
					<div className={styles.title}>
						{inlineTranslation({
							dk: 'ADMINISTRATORER',
							no: 'ADMINISTRATORER',
							se: 'ADMINISTRATORER',
							en: 'ADMINISTRATORS',
						})}
					</div>
					{inlineTranslation({
						dk: (
							<>
								Administratorerne både ejer og sørger for den daglige drift af Hundeparken.
								<br />
								Det er dem, som er de øverste ansvarlige, og som har det sidste ord i alle afgørelser i Hundeparken.
							</>
						),
						no: (
							<>
								Administratorene både eier og sørger for den daglige driften av Hundeparken.
								<br /> Det er de som har det øverste ansvaret og siste ordet i alle avgjørelser.
							</>
						),
						se: (
							<>
								Administratörerna både äger och har hand om den dagliga driften av Hundparken.
								<br />
								Det är de som har det högsta ansvaret och det sista ordet i alla beslut.
							</>
						),
						en: (
							<>
								The Administrators both own and take care of the daily operations of Hundeparken.
								<br />
								They are the ones who have the highest responsibility, and the last word in all decisions.
							</>
						),
					})}
				</div>
			</div>

			<div className={classNames(styles.rang, styles.seperator2)}>
				<div className={styles.img}>
					<img src="/images/whatis/mod.png" alt="" />
				</div>
				<div className={styles.desc}>
					<div className={styles.title}>
						{inlineTranslation({
							dk: 'MODERATORER',
							no: 'MODERATORER',
							se: 'MODERATORER',
							en: 'MODERATORS',
						})}
					</div>
					{inlineTranslation({
						dk: (
							<>
								Moderatorer er personer, som arbejder frivilligt for Hundeparken.
								<br />
								Deres opgave er, at sørge for at Hundeparken er et trygt sted at være for alle brugerne, som er der. De
								har evnen til at spidde og smide hunde ud, som ikke følger reglerne i kortere eller længere perioder.
								Moderatorerne er underlagt administratorerne.
							</>
						),
						no: (
							<>
								Moderatorene er personer som jobber frivillig for Hundeparken.
								<br />
								Deres oppgave er å sørge for at Hundeparken er et trygt sted å være for alle brukerne som er der. De har
								evnen til å spidde og kicke ut hunder som ikke oppfører seg i kortere eller lengre perioder.
								Moderatorene er underlagt administratorene.
							</>
						),
						se: (
							<>
								Moderatorer är personer som frivilligt jobbar för Hundparken.
								<br />
								Deras uppgift är att se till att Hundparken är ett tryggt ställe att vara på för alla användare. De har
								möjlighet att spetsa och kicka ut hundar som inte uppför sig, i kortare eller längre perioder.
								Moderatorerna jobbar för administratörerna.
							</>
						),
						en: (
							<>
								The moderatos are people who work voluntarily for Hundeparken, they are chosen by admins and other
								moderators. Their task is to make sure that Hundeparken is a safe space for all its users. They have the
								ability to spear and kick out dogs who misbehave in shorter and longer periods of time. The moderators
								are subordinate to the administrators.
							</>
						),
					})}
				</div>
			</div>

			<div className={classNames(styles.rang, styles.seperator2)}>
				<div className={styles.img}>
					<img src="/images/whatis/dev.png" alt="" />
				</div>
				<div className={styles.desc}>
					<div className={styles.title}>
						{inlineTranslation({
							dk: 'UDVIKLER',
							no: 'UTVIKLERE',
							se: 'UTVIKLER',
							en: 'DEVELOPER',
						})}
					</div>
					{inlineTranslation({
						dk: 'En udvikler står for udvikling og den tekniske drift af Hundeparken. Han sørger for, at alt det tekniske fungerer optimalt, og at Parken får nye og bedre funktioner.',
						no: 'Utviklerne står for utvikling og teknisk drift av Hundeparken. Det er de som sørger for at alt det tekniske fungerer optimalt og at parken hele tiden får nye og bedre funksjoner.',
						se: 'Utvecklare står för utveckling och teknisk drift av Hundparken. Det är han som ser till att allt det tekniska fungerar optimalt och att parken hela tiden får nya och bättre funktioner.',
						en: 'The developer is responsible for the development and technical operation of Hundeparken. <br>He is the one who makes sure that all the technological aspects work optimally, and he supplies the park with new and better functions.',
					})}
				</div>
			</div>

			<div className={classNames(styles.rang, styles.seperator)} id="badges">
				<div className={styles.img}>
					<img src="/images/whatis/client.png" alt="" />
				</div>
				<div className={styles.desc}>
					<div className={styles.title}>
						{inlineTranslation({
							dk: 'CLIENT DEVELOPER',
							no: 'CLIENT DEVELOPER',
							se: 'CLIENT DEVELOPER',
							en: 'CLIENT DEVELOPER',
						})}
					</div>
					{inlineTranslation({
						dk: 'Medlem af Hundeparkens udviklingsteam.',
						no: 'Står for utvikling og bugfiksing av Hundeparken. <br>Han er medlem av Hundeparkens utviklingsteam og sørger for at parken hele tiden får nye og bedre funksjoner.',
						se: 'Medlem av Hundparkens utvecklingsteam.',
						en: "Responsible for development and bug fixing of Hundeparken. <br> He's a member of Hundeparken's development team and ensures that the park constantly gets new and better functions",
					})}
				</div>
			</div>

			<div className={classNames(styles.rang, styles.seperator2)}>
				<div className={styles.img}>
					<img src="/images/whatis/designer.png" alt="" />
				</div>
				<div className={styles.desc}>
					<div className={styles.title}>
						{inlineTranslation({
							dk: 'DESIGNER',
							no: 'DESIGNERE',
							se: 'DESIGNER',
							en: 'DESIGNER',
						})}
					</div>
					{inlineTranslation({
						dk: 'Designteamet består af en fast gruppe, som arbejder med at lave diverse grafik og objekter til Hundeparken.',
						no: 'Designteamet består av en fast gruppe, som jobber med å lage diverse grafikk og objekter for Hundeparken.',
						se: 'Designteamet består av en fast grupp, som arbetar med att skapa diverse grafik ock objekt till Hundparken.',
						en: 'The design-team consists of a permanent group of people who develop various graphical designs and objects for Hundeparken.',
					})}
				</div>
			</div>

			<div className={classNames(styles.rang, styles.seperator2)}>
				<div className={styles.img}>
					<img src="/images/whatis/fk.png" alt="" />
				</div>
				<div className={styles.desc}>
					<div className={styles.title}>
						{inlineTranslation({
							dk: 'FESTKOMITÉEN',
							no: 'FESTKOMITÉEN',
							se: 'FESTKOMMITTÉN',
							en: 'THE PARTY COMMITTEE',
						})}
					</div>
					{inlineTranslation({
						dk: 'Festkomitéen er udvalgte brugere som har ansvar for at holde aktiviteter og konkurrencer i Hundeparken.',
						no: 'Festkomitéen er utvalgte brukere som har ansvar for å arrangere aktiviteter og konkurranser i Hundeparken.',
						se: 'Festkommittén är utvalda medlemmar som har ansvar för att hålla aktiviteter och tävlingar  i Hundparken.',
						en: 'The party committee consists of selected users who are responsible for arranging activities and competitions in Hundeparken.',
					})}
				</div>
			</div>
		</div>
	);
};
