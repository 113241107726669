import { HighscoreInterval, MiniGame } from '../../graphql-types';
import { FunctionComponent } from 'react';
import { useGetHighscoreQuery } from './Highscore.graphql';
import styles from './Highscore.module.scss';
import { useLanguageContext } from '..';

export const HighscorePanel: FunctionComponent<{ interval: HighscoreInterval; game: MiniGame }> = ({
	interval,
	game,
}) => {
	const { data } = useGetHighscoreQuery({ variables: { game, interval } });
	const { inlineTranslation } = useLanguageContext();

	return (
		<div className={styles.panel}>
			<h2>{interval}</h2>
			<table>
				<thead>
					<tr>
						<th>
							{inlineTranslation({
								dk: 'Poistion',
								no: 'Posisjon',
								se: 'Placera',
								en: '',
							})}
						</th>
						<th>
							{inlineTranslation({
								dk: 'Navn',
								no: 'Navn',
								se: 'Namn',
								en: '',
							})}
						</th>
						<th>Score</th>
					</tr>
				</thead>
				<tbody>
					{data?.highscores.map(highscore => (
						<tr data-position={highscore.position} data-is-me={highscore.isMe ? 1 : 0} key={highscore.dogname}>
							<td>{highscore.position}</td>
							<td>{highscore.dogname}</td>
							<td>{highscore.score}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
