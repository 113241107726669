import {
	ColoredBox,
	HeaderedButton,
	// CompetitionBox,
	RainbowBox,
	IntroductionBox,
	AnnouncementsBox,
	SocialsBox,
	DiscordBox,
	Gallery,
	useLanguageContext,
	MerchBox,
	RetroButton,
} from '../components';
import { FunctionComponent } from 'react';
import style from './Frontpage.module.scss';
import ParagrafImage from '../images/paragraf.png';
import SOSImage from '../images/sos.png';
import AdminImage from '../images/admin_pic.png';
import CreditsImage from '../images/cred-pic.png';
import { Link } from 'react-router-dom';

export const FrontPage: FunctionComponent = () => {
	const { getTranslation, inlineTranslation } = useLanguageContext();
	return (
		<div className={style.page}>
			<div className={style.container}>
				<div className={style.left}>
					<IntroductionBox />

					<ColoredBox color="#499aff" title="Highscores">
						{getTranslation('HIGHSCORE_BOX')}
					</ColoredBox>

					<MerchBox />
				</div>
				<div className={style.middle}>
					<RainbowBox />
					<Gallery images={['59.png', '58.png', '56.png']} />
					<a href="https://patreon.com/hundeparken" target="_blank" rel="noreferrer" style={{ paddingTop: '20px' }}>
						<RetroButton
							color="patreon-orange"
							style={{
								display: 'flex',
								alignItems: 'center',
								// justifyContent: 'space-',
								justifyContent: 'center',
								gap: '10px',
								height: '50px',
								// fontSize: '14px',
								width: '100%',
							}}
							className="text-white w-full">
							<img src="/images/patreon/logo.svg" height="12px" alt="patreon logo" />
							<p>
								{inlineTranslation({
									dk: 'Støt os på Patreon',
									no: 'Støtt oss på Patreon',
									se: 'Stödja oss på Patreon',
									en: 'Support us on Patreon',
								})}
							</p>
						</RetroButton>
					</a>
					<div className={style.infoLine}>
						<Link to="/rules">
							<HeaderedButton
								color="#fec93c"
								hoverColor="#00ccff"
								image={ParagrafImage}
								title={inlineTranslation({
									dk: ['LOVE & REGLER', 'I HUNDEPARKEN'],
									no: ['LOVER & REGLER', 'I HUNDEPARKEN'],
									se: ['REGLER I', 'HUNDEPARKEN'],
									en: ['LAWS & RULES', 'Of the park'],
								})}
							/>
						</Link>
						<Link to="/help">
							<HeaderedButton
								color="#ff8600"
								hoverColor="#6ec81d"
								image={SOSImage}
								title={inlineTranslation({
									dk: ['Har du brug', 'For hjælp?'],
									no: ['Trenger du', 'litt hjelp?'],
									se: ['Behöver', 'du hjälp?'],
									en: ['Need some', 'help?'],
								})}
							/>
						</Link>
						<Link to="/team">
							<HeaderedButton
								color="#ff00ac"
								hoverColor="#00ccff"
								image={AdminImage}
								title={inlineTranslation({
									dk: ['Holdet bag', 'Hundeparken'],
									no: ['Hvem har ansvaret', 'i parken?'],
									se: ['Vem har ansvaret', 'i parken?'],
									en: ["Who's in charge", 'of the park?'],
								})}
							/>
						</Link>
					</div>
					<div className={style.infoLine}>
						<Link to="/credits">
							<HeaderedButton
								color="#d800ff"
								hoverColor="#6ec81d"
								image={CreditsImage}
								title={inlineTranslation({
									dk: ['Hundeparkens', 'Støttere'],
									no: ['Hundeparkens', 'støttespillere'],
									se: ['Hundeparkens', 'Stödspelare'],
									en: ["The park's", "Supporter's"],
								})}
							/>
						</Link>
						<DiscordBox />
					</div>

					<SocialsBox />
				</div>
				<div className={style.right}>
					<AnnouncementsBox />
					<ColoredBox color="#ffa500" title={getTranslation('FK_BOX_TITLE')}>
						{getTranslation('FK_BOX_DESC')}
					</ColoredBox>
				</div>
			</div>
		</div>
	);
};
