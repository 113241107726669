import { FunctionComponent } from 'react';
import { useLanguageContext, Lang, validateInput } from '../../components';
import styles from './NewUserPage.module.scss';
import { useCreateUserMutation, CreateUserError } from './NewUser.graphql';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AUTH_TOKEN_NAME } from '../../constants';

export const NewUserPage: FunctionComponent = () => {
	const { language, setLanguage } = useLanguageContext();
	const navigate = useNavigate();
	const [createUser] = useCreateUserMutation();
	const { register, handleSubmit, setValue, watch } = useForm<{
		lang: Lang;
		username: string;
	}>({
		defaultValues: {
			lang: language,
			username: '',
		},
	});
	const pickedLanguage = watch('lang');

	const onSubmit = handleSubmit(async data => {
		const response = await createUser({
			variables: {
				token: localStorage.getItem('id_token') || '',
				username: data.username,
				language: pickedLanguage,
			},
		});
		const userResponse = response.data?.createUser;

		if (userResponse?.valid === CreateUserError.UsernameTaken) {
			alert('Username is already in use');
			return;
		}

		localStorage.removeItem('id_token');

		if (userResponse?.valid === CreateUserError.InvalidToken) {
			alert('Google login expired, try again');
			navigate('/');
			return;
		}

		if (userResponse?.token) {
			localStorage.setItem(AUTH_TOKEN_NAME, userResponse.token);
		}

		setLanguage(data.lang);
		(window as any).location = '/h5/choose/new';
	});

	return (
		<div className={styles.content}>
			<form onSubmit={onSubmit}>
				<br />
				<br />
				<div className="logo">
					<img src="/images/translations/logo_dk.png" alt="Hundeparken logo" />
				</div>
				<div className={styles.window}>
					<h1>New user</h1>
					Username
					<input
						type="text"
						id="username"
						{...register('username', {
							onBlur: e => validateInput(e.target as HTMLInputElement, true),
							onChange: e => validateInput(e.target as HTMLInputElement),
						})}
						required
					/>
					Language
					<div className={styles.langs}>
						{Object.values(Lang).map(lang => (
							<div className={styles.lang} key={lang}>
								<input
									type="radio"
									name="lang"
									value={lang}
									id={`flag${lang}`}
									defaultChecked={pickedLanguage === lang}
									onClick={() => setValue('lang', lang)}
								/>
								<label htmlFor={`flag${lang}`}>
									<img src={`https://hundeparken.net/images/translations/flag_${lang}.png`} alt={`${lang} flag`} />
								</label>
							</div>
						))}
					</div>
					<br />
					<button type="submit">OK</button>
				</div>
			</form>
		</div>
	);
};
