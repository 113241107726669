import { FunctionComponent, PropsWithChildren } from 'react';
import styles from './RetroButton.module.scss';
import classNames from 'classnames';

export const RetroButton: FunctionComponent<
	PropsWithChildren<
		React.ButtonHTMLAttributes<HTMLButtonElement> & { color: 'darkblue' | 'blue' | 'red' | 'pink' | 'patreon-orange' }
	>
> = props => {
	return (
		<div className={styles.container} style={props.style} data-color={props.color}>
			<button {...props} className={classNames(styles.button, props.className)}>
				{props.children}
			</button>
		</div>
	);
};
