import { Editor, loadScript, useLanguageContext, ModBox, RetroButton, ChatLog } from '../../components';
import { FunctionComponent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMyUserQuery } from './Client.graphql';
import './Client.scss';
import { VERSION } from '../../paths';
import { AUTH_TOKEN_NAME } from '../../constants';

export const ClientPage: FunctionComponent = () => {
	const { data, loading } = useMyUserQuery();
	const { language } = useLanguageContext();
	const [searchParams] = useSearchParams();
	const myUser = data?.myUser;
	const isMagicBanana = new URL(document.URL).searchParams.get('magic') !== null;
	const isApp = new URL(document.URL).searchParams.get('app') === '1';
	const isClientDisabled = data?.getSetting === '1' && !isMagicBanana;
	const isCompatibility = searchParams.get('compatiblity') !== null;
	const gameExtension = isCompatibility ? '.compatiblity' : '';

	useEffect(() => {
		document.body.dataset.client = '1';
		document.body.dataset.grid = 'no';
		return () => {
			document.body.dataset.client = '0';
			document.body.dataset.grid = 'yes';
		};
	});

	useEffect(() => {
		if (!data && !loading && !isApp) {
			(window as any).location = '/';
		}
	}, [data?.myUser, loading]);

	useEffect(() => {
		if (!!myUser || isApp) {
			const admin = isMagicBanana ? 1 : myUser?.admin ?? 0;
			const mod = isMagicBanana ? 1 : myUser?.moderator ?? 0;

			const win = window as any;
			win.loaded = isCompatibility;
			win.language = language;
			win.myID = parseInt(searchParams.get('id') || '0');
			win.adm = admin;
			win.admin = admin;
			win.mod = mod;
			win.isApp = isApp;
			win.authtoken = searchParams.get('token') || localStorage.getItem(AUTH_TOKEN_NAME);
			win.version = VERSION;
		}
	}, [...Array.from(searchParams.values()), myUser]);

	useEffect(() => {
		if ((!!myUser || isApp) && !isClientDisabled) {
			document.title = `Hundeparken - Client`;
			loadScript(`https://hundeparken.net/h5/game/vendors.js?${VERSION}`, 'module').then(() => {
				loadScript(`https://hundeparken.net/h5/game/scripts/compatiblity.js?${VERSION}`, 'module');
				loadScript(`https://hundeparken.net/h5/game/game${gameExtension}.js?${VERSION}`, 'module');
			});

			return () => {
				console.log('Redirected?');
				(window as any).reload();
			};
		}
	}, [myUser, isClientDisabled]);

	if (isClientDisabled) {
		return (
			<div
				style={{
					color: 'red',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: '20px',
					gap: '10px',
					fontSize: '32px',
					fontFamily: 'hppixels',
				}}>
				Hundeparken is unavailable at the moment.
				<br />
				<br />
				<RetroButton color="blue" style={{ width: '300px', height: '40px' }} onClick={() => window.location.reload()}>
					RETRY
				</RetroButton>
			</div>
		);
	}

	return (
		<div className="client">
			<table className="gamecontent">
				<tr>
					<td className="gamearea">
						<div className="gamewindow" />
					</td>
				</tr>
			</table>
			<div className="chatwindow">
				<textarea
					className="chattextarea"
					autoComplete="off"
					autoCorrect="off"
					autoCapitalize="off"
					spellCheck="false"
				/>
			</div>
			<div className="compatiblity-warning" id="compatiblity-warning">
				Loader parken ikke? <a href={`${window.location.href}&compatiblity=1`}>Brug compatiblity mode</a>
			</div>

			<ChatLog />

			<div className="warp window">
				<div className="header windowheader">
					<table style={{ width: '100%' }}>
						<tr>
							<td>
								<img alt="" src="/h5/game/gfx/ui/warp/logo.png" className="logo" />
							</td>
							<td style={{ textAlign: 'right' }}>
								<img alt="" src="/h5/game/gfx/ui/warp/close.png" className="closewindow windowButton fadeOnHover" />
							</td>
						</tr>
					</table>
				</div>
				<div className="body">
					<div className="warpwindow"></div>
				</div>
			</div>
			<div className="poop window">
				<table>
					<tr>
						<td className="logo header">
							<img alt="" src="/h5/game/gfx/ui/poop/logo.png" />
						</td>
						<td>
							<div
								style={{
									borderBottom: '2px solid #FFD494',
									borderRight: '2px solid #FFD494',
								}}>
								<div
									style={{
										borderTop: '2px solid #9C6514',
										borderLeft: '2px solid #9C6514',
									}}>
									<input className="message" />
								</div>
							</div>
						</td>
						<td className="button">
							<img alt="" src="/h5/game/gfx/ui/poop/button.png" className="sendpoop" />
						</td>
						<td className="close">
							<img alt="" src="/h5/game/gfx/ui/poop/close.png" className="closewindow" />
						</td>
					</tr>
				</table>
			</div>

			<div className="dogsearch window">
				<table>
					<tr>
						<td className="logo header">
							<img alt="" src="/h5/game/gfx/ui/searchdog/logo.png" />
						</td>
						<td>
							<div
								style={{
									borderBottom: '2px solid #E469EF',
									borderRight: '2px solid #E469EF',
								}}>
								<div
									style={{
										borderTop: '2px solid #723477',
										borderLeft: '2px solid #723477',
									}}>
									<input className="message" />
								</div>
							</div>
						</td>
						<td className="button">
							<img alt="" src="/h5/game/gfx/ui/searchdog/button.png" className="searchdogbtn" />
						</td>
						<td className="close">
							<img alt="" src="/h5/game/gfx/ui/searchdog/close.png" className="closewindow" />
						</td>
					</tr>
				</table>
			</div>
			<div className="anmeld window">
				<table>
					<tr>
						<td className="logo header">
							<img alt="" src="/h5/game/gfx/ui/anmeld/logo.png" />
						</td>
						<td className="close">
							<img alt="" src="/h5/game/gfx/ui/searchdog/close.png" className="closewindow" />
						</td>
					</tr>
				</table>
				<div className="body">
					<div className="inputbox">
						<span className="reporttext"></span>
						<br />
						<select id="offendingdogs">
							<option>Velg hund i rommet:</option>
						</select>
					</div>
					<table className="selectoffencebox">
						<tr>
							<td>
								<input type="radio" id="language" name="offence" value="language" />
							</td>
							<td>
								<label htmlFor="language"></label>
							</td>
						</tr>
						<tr>
							<td>
								<input type="radio" id="thief" name="offence" value="thief" />
							</td>
							<td>
								<label htmlFor="thief">Tyveri</label>
							</td>
						</tr>
						<tr>
							<td>
								<input type="radio" id="cheat" name="offence" value="cheat" />
							</td>
							<td>
								<label htmlFor="cheat">Cheating eller hacking</label>
							</td>
						</tr>
						<tr>
							<td>
								<input type="radio" id="serious" name="offence" value="serious" />
							</td>
							<td>
								<label htmlFor="serious">Alvorlig regelbrudd</label>
							</td>
						</tr>
						<tr>
							<td>
								<input type="radio" id="other" name="offence" value="other" />
							</td>
							<td>
								<label htmlFor="other">Annet (vennligst forklar)</label>
							</td>
						</tr>
					</table>
					<div>
						<textarea placeholder="Oppgi grunnlag..." id="anmeld-text"></textarea>
					</div>
					<div style={{ textAlign: 'right' }}>
						<button id="report_submit"></button>
					</div>
				</div>
				<div className="body2" style={{ display: 'none' }}>
					<div>Reported</div>
					<div>
						<button>Report more</button>
					</div>
				</div>
			</div>
			<div className="profile window">
				<div className="header windowheader">
					<table style={{ width: '100%' }}>
						<tr>
							<td>
								<img alt="" src="/h5/game/gfx/snuseprofil/logo.png" className="logo" />
							</td>
							<td style={{ textAlign: 'right' }}>
								<img alt="" src="/h5/game/gfx/ui/warp/close.png" className="closewindow windowButton fadeOnHover" />
							</td>
						</tr>
					</table>
				</div>
				<div className="body">
					<img alt="" src="/h5/game/gfx/snuseprofil/background.png" />
				</div>
			</div>
			<div className="window youtubewindow" style={{ backgroundColor: '#9C6514', display: 'none' }}>
				<div className="header windowheader">
					<table style={{ width: '100%' }}>
						<tr>
							<td>
								<img alt="" src="/h5/game/gfx/ui/youtube.png" className="logo" />
							</td>
							<td style={{ textAlign: 'right' }}>
								<img alt="" src="/h5/game/gfx/ui/warp/close.png" className="closewindow windowButton fadeOnHover" />
							</td>
						</tr>
					</table>
				</div>
				<div className="body">
					<div id="player"></div>
				</div>
			</div>

			{myUser?.admin && myUser.admin !== 0 && <Editor />}
			{myUser?.moderator && myUser.moderator !== 0 && <ModBox />}
		</div>
	);
};
