import classNames from 'classnames';
import { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import styles from './Chatlog.module.scss';

interface Chatlog {
	dogId: number;
	name: string;
	message: string;
	createdAt: string;
	color: string;
	id: number;
}

export const ChatLog: FunctionComponent = () => {
	const [highlighedIds, setHighlighedIds] = useState<number[]>([]);
	const [chatlogs, setChatlogs] = useState<Chatlog[]>([]);

	useEffect(() => {
		(window as any)._addChatlog = (newLog: Chatlog) => {
			setChatlogs(logs => [{ ...newLog, id: Date.now() }, ...logs].filter((_, index) => index < 500));
		};
	});

	const toggleId = (id: number) => {
		setHighlighedIds(IDs => {
			if (IDs.includes(id)) {
				return IDs.filter(oldId => oldId !== id);
			}

			return [...IDs, id];
		});
	};

	return (
		// <div
		// 	className="chatlog window"
		// 	style={{ position: 'absolute', zIndex: 8, display: 'initial', left: '10px', top: '483px' }}>
		<div className="chatlog window" style={{ position: 'absolute' }}>
			<div className="header windowheader">
				<table>
					<tr>
						<td>
							<img alt="" src="/h5/game/gfx/ui/chatlog/chatlog.png" className="logo" />
						</td>
						<td style={{ textAlign: 'right' }}>
							<img
								alt=""
								src="/h5/game/gfx/ui/chatlog/smaller_text.png"
								className="smallertext fadeOnHover windowButton"
							/>
							<img
								alt=""
								src="/h5/game/gfx/ui/chatlog/larger_text.png"
								className="largertext fadeOnHover windowButton"
							/>
							<img alt="" src="/h5/game/gfx/ui/chatlog/close.png" className="closewindow windowButton fadeOnHover" />
						</td>
					</tr>
				</table>
			</div>
			<div className="body">
				<table id="chattable" style={{ contentVisibility: 'auto' }} className={styles.chatlog}>
					{chatlogs.map(chatlog => {
						const isHighlighted = highlighedIds.includes(chatlog.dogId);
						return (
							<tr
								key={chatlog.id}
								onClick={() => toggleId(chatlog.dogId)}
								className={classNames({
									markedTextRow: isHighlighted,
									[styles.markedTextRow]: isHighlighted,
									unMarkedUserRow: !isHighlighted,
								})}
								data-dogid={chatlog.dogId}>
								<td className="time" style={{ '--color': chatlog.color } as CSSProperties}>
									{chatlog.createdAt}
								</td>
								<td className="name">{chatlog.name}</td>
								<td className="message">{chatlog.message}</td>
							</tr>
						);
					})}
				</table>
			</div>
		</div>
	);
};
