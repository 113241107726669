import { FunctionComponent } from 'react';
import styles from './Header.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useLanguageContext } from '../Language/LanguageContext';
import { Lang } from '../Language/utils';
import { useSeason } from '..';

export const Header: FunctionComponent = () => {
	const { season } = useSeason();
	const { setLanguage, language } = useLanguageContext();
	return (
		<div>
			<div className={styles.flagContainer}>
				{Object.values(Lang).map(lang => (
					<div
						className={classNames(styles.flag, {
							[styles.flagActive]: lang === language,
						})}
						onClick={() => setLanguage(lang as Lang)}
						key={lang}>
						<img src={`/images/translations/flag_${lang}.png`} alt={lang} />
					</div>
				))}
			</div>
			<div
				className={classNames(styles.header, {
					[styles.normal]: season === 'normal',
					[styles.autumn]: season === 'autumn',
					[styles.winter]: season === 'winter',
				})}>
				<div className={styles.clouds}>
					{[...Array(4 * 3)].map((_, index) => {
						const i = (index % 4) + 1;
						return <img key={index} src={`/images/header/cloud${i}.png`} data-cloud={i} alt="" />;
					})}
				</div>
				<div className={styles.mountains}>
					{[...Array(5)].map((_, index) => {
						const i = (index % 5) + 1;
						return <img key={index} src={`/images/header/mountain.png`} data-mountain={i} alt="" />;
					})}
				</div>
				<Link to="/" className={styles.logo}>
					<img src="/images/translations/logo_dk.png" alt="Hundeparken logo" />
				</Link>
			</div>
		</div>
	);
};
