import { loadScript, validateInput } from '../../components';
import { IS_DEV, VERSION } from '../../paths';
import { FunctionComponent, useEffect } from 'react';
import styles from './NewDogPage.module.scss';
import classNames from 'classnames';
import { useNewDogQuery, useCreateNewDogMutation } from './NewDog.graphql';
import { useNavigate, useParams } from 'react-router-dom';

export const NewDogPage: FunctionComponent = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [createDog] = useCreateNewDogMutation();
	const { data } = useNewDogQuery();
	const newDog = data?.newDog;

	(window as any).signUp = async (name: string) => {
		if (!newDog) {
			return;
		}

		const response = await createDog({
			variables: {
				name,
				token: newDog.token,
				dogId: id ? parseInt(id) : undefined,
			},
		});

		const errorMessage = response.data?.newDog?.message;
		if (errorMessage) {
			return errorMessage;
		}

		navigate(`/h5/choose`);
	};

	useEffect(() => {
		if (!newDog) {
			return;
		}

		const win = window as any;
		win.color = newDog.color;
		win.shadowcolor = newDog.shadowColor;
		win.version = VERSION;

		document.title = `Hundeparken - New dog`;
		loadScript(`https://hundeparken.net/h5/game/vendors.js?${VERSION}`, 'module').then(() => {
			loadScript(`https://hundeparken.net/h5/game/new_user.js?${VERSION}`, 'module');
		});
	}, [newDog]);
	return (
		<div className={styles.centring}>
			<div className={classNames(styles.canvas, 'canvas')}>
				<input
					id="dogname"
					className={styles.dogname}
					style={IS_DEV ? { backgroundColor: 'red' } : {}}
					onBlur={e => validateInput(e.target, true)}
					onChange={e => validateInput(e.target)}
					maxLength={14}
				/>
			</div>
		</div>
	);
};
