import { FunctionComponent } from 'react';

export const Editor: FunctionComponent = () => {
	return (
		<div className="editor window">
			<div className="header windowheader">
				<b>Editor</b>
				<input type="checkbox" className="editmodeenabled" id="editmodeenabled" />
				<label htmlFor="editmodeenabled">Editmode</label>
				<input type="checkbox" id="disableChat" />
				<label htmlFor="disableChat">Disable chat</label>
			</div>
			<div className="body">
				<form
					onChange={e => {
						const elem = e.target as HTMLInputElement;
						Array.from(document.querySelectorAll<HTMLElement>('.editortabs')).forEach(d => (d.style.display = 'none'));
						const tab = document.querySelector<HTMLElement>(`.editortabs.${elem.value}-tab`);
						if (tab) {
							tab.style.display = 'initial';
						}
					}}>
					<div className="smallheader editortabselector">
						<input type="radio" name="updatetype" className="updatetype" id="tile-radio" value="tile" />
						<label htmlFor="tile-radio">Tile</label> -
						<input type="radio" name="updatetype" className="updatetype" id="item-radio" value="item" />
						<label htmlFor="item-radio">Item</label> -
						<input type="radio" name="updatetype" className="updatetype" id="color-radio" value="color" />
						<label htmlFor="color-radio">Color</label> -
						<input type="radio" name="updatetype" className="updatetype" id="shop-radio" value="shop" />
						<label htmlFor="shop-radio">Shop</label> -
						<input type="radio" name="updatetype" className="updatetype" id="sprite-radio" value="sprite" />
						<label htmlFor="sprite-radio">Sprite</label> -
						<input type="radio" name="updatetype" className="updatetype" id="room-radio" value="room" />
						<label htmlFor="room-radio">Room</label> -
						<input type="radio" name="updatetype" className="updatetype" id="tileaccess-radio" value="tileaccess" />
						<label htmlFor="tileaccess-radio">TileAccess</label> -
						<input type="radio" name="updatetype" className="updatetype" id="knogle-radio" value="knogleknas" />{' '}
						<label htmlFor="knogle-radio">KnogleKnas</label> -
						<input type="radio" name="updatetype" className="updatetype" id="abilit-radio" value="abilities" />{' '}
						<label htmlFor="abilit-radio">Abilities</label> -
						<input type="radio" name="updatetype" className="updatetype" id="action-radio" value="action" />{' '}
						<label htmlFor="action-radio">Action</label> -
						<input type="radio" name="updatetype" className="updatetype" id="chatcmds-radio" value="chatcmds" />{' '}
						<label htmlFor="chatcmds-radio">Commands</label> -
						<input
							type="radio"
							name="updatetype"
							className="updatetype"
							id="announcement-radio"
							value="announcement"
						/>{' '}
						<label htmlFor="announcement-radio">Announcements</label> -
						<input type="radio" name="updatetype" className="updatetype" id="user-radio" value="user" />
						<label htmlFor="user-radio">User</label>
					</div>
				</form>
				<div className="editortabs tile-tab">
					<h2>Tile editor</h2>
					<input id="tileid" value="0" data-style="width: 50px;" />
					<img alt="" id="tilepreview" src="game/gfx/tile/0.png" />
					<select id="groundType">
						<option value="0">Vanlig</option>
						<option value="1">Vann</option>
						<option value="3">Dig</option>
						<option value="2">Forbudt</option>
					</select>

					<div id="tileContainer"></div>
				</div>
				<div className="editortabs chatcmds-tab">
					<h2>Chat commands</h2>
					<div id="chatcommands"></div>
				</div>
				<div className="editortabs item-tab">
					<h2>Item editor</h2>
					<select id="itemid" className="itemid">
						<option value="0">Fjern</option>
					</select>
					<button className="additemtome">Give to me</button>
				</div>
				<div className="usercolor editortabs color-tab">
					<h2>Color changer</h2>
					<input type="radio" name="colortype" value="body" />
					Body <input type="radio" name="colortype" value="chat" />
					Chat
					<input type="radio" name="colortype" value="shadow" />
					Shadow
					<br />
					<input className="jscolor {onFineChange:''}" type="color" id="jscolorpicker" value="#000000" />
					<button id="setusercolor">Set color</button>
					<button id="randomColorBtn">Random</button>
				</div>
				<div className="editortabs shop-tab">
					<h2>Shop Creator</h2>
					<h5>Input ShopID and right click on which item should be the shop.</h5>
					<input id="editor-shopid" placeholder="Shop ID" />
					<br />
				</div>
				<div className="editortabs sprite-tab">
					<h2>Sprite Adjustment</h2>
					<h5>Select Sprite to show. Remember 0 is also a frame!</h5>
					<select id="spritename">
						<option value="">-Choose-</option>
					</select>
					<button id="playSprite">Play</button>
					<br />
					<button id="prevSpriteFrame">&ltrif;</button>
					<span id="currentSpriteFrame">0</span>/<span id="totalSpriteFrames">0</span>
					<button id="nextSpriteFrame">&rtrif;</button>
					<br />
					<br />
					Item pos:
					<select id="spriteItemPos">
						<option value="hat">hat</option>
						<option value="mouth">mouth</option>
						<option value="back">back</option>
						<option value="ass">ass</option>
					</select>
					<br />
					<button id="spriteItemUp">&uparrow;</button>
					<button id="spriteItemDown">&downarrow;</button>
					<button id="spriteItemLeft">&leftarrow;</button>
					<button id="spriteItemRight">&rightarrow;</button>
					<button id="spriteItemDump">Dump</button>
				</div>
				<div className="editortabs tileaccess-tab">
					<h2>Tile Access</h2>
					<h5>
						Right-Click to select tile in editmode.
						<br />
						NO EMPTY VALUES WHEN SAVING!
					</h5>
					<table>
						<tr>
							<td>X</td>
							<td>
								<input id="x" />
							</td>
						</tr>
						<tr>
							<td>Y</td>
							<td>
								<input id="y" />
							</td>
						</tr>
						<tr>
							<td>Kobbel</td>
							<td>
								<select id="kobbel">
									<option value="-1">None</option>
									<option value="0">Banden</option>
									<option value="1">Klanen</option>
									<option value="2">Losjen</option>
									<option value="3">Sekten</option>
									<option value="4">Selskapet</option>
									<option value="5">Stammen</option>
								</select>
							</td>
						</tr>
						<tr>
							<td>ItemID</td>
							<td>
								<input id="itemid" />
							</td>
						</tr>
						<tr>
							<td>Owned itemID</td>
							<td>
								<input id="owned_item" />
							</td>
						</tr>
						<tr>
							<td>Karma</td>
							<td>
								<input id="karma" />
							</td>
						</tr>
						<tr>
							<td>Level</td>
							<td>
								<select id="level">
									<option value="0">Valp</option>
									<option value="3">Slede</option>
									<option value="82">Spor</option>
									<option value="180">Vann</option>
									<option value="350">Rom</option>
									<option value="657">Gjøgler</option>
									<option value="1353">Ninja</option>
									<option value="2992">Morph</option>
								</select>
							</td>
						</tr>
						<tr>
							<td>Moderator</td>
							<td>
								<input id="moderator" />
							</td>
						</tr>
						<tr>
							<td>Admin</td>
							<td>
								<input id="admin" />
							</td>
						</tr>
						<tr>
							<td></td>
							<td>
								<button id="settileaccessbutton">Save</button>
							</td>
						</tr>
					</table>
				</div>
				<div className="editortabs knogleknas-tab">
					<h2>KnogleKnas editor</h2>

					<select id="knogleMaps">
						<option value="0">Loading maps</option>
					</select>
					<button id="editKnogle">Edit</button>
					<button id="deleteKnogle">Delete</button>
					<button id="cloneKnogle">Clone</button>
					<button id="testKnogle">Test map</button>
					<br />
					<button id="newKnogle">New map</button>
					<br />
					<br />
				</div>
				<div className="editortabs announcement-tab">
					<h2>New message</h2>
					<div>
						Title:
						<br />
						<input id="announcement-title" placeholder="Title" />
						<br />
						<br />
						Message: <br />
						<textarea id="announcement-message" placeholder="Message" />
						<br />
						<br />
						Image url:
						<br />
						<input id="announcement-url" placeholder="Url" />
						<br />
						<br />
						Reactions: <br />
						<select id="announcement-reactions" multiple>
							<option value="clap">Clap</option>
							<option value="heart">Heart</option>
							<option value="thumbs-up">Thumbs-up</option>
							<option value="thumbs-down">Thumbs-down</option>
						</select>
						<br />
						<br />
						Type:
						<br />
						<select id="announcement-type">
							<option value="0">Information</option>
							<option value="1">Danger</option>
							<option value="2">NonImportant</option>
							<option value="3">Changelog</option>
						</select>
						<br />
						<br />
						<button id="announcement-submit">Send announcement</button>
					</div>
				</div>
				<div className="editortabs action-tab">
					<h2>Action editor</h2>
					<h5>Right click on which item should be the action.</h5>
					<input id="editor-stepon" placeholder="Step on" />
					<br />
					<input id="editor-buttonid" placeholder="Button-id" />
					<br />
				</div>
				{/* <script>
			document.addEventListener("DOMContentLoaded", () => {
				document.querySelector(".updateitemeditor")?.addEventListener("click", function(){
					var itemlist = document.querySelector(".itemid");
					itemlist.innerHTML = ('<option value="0">Fjern</option>');
					Items.itemList.forEach(function(item){
						itemlist.innerHTML += ('<option value="'+item.id+'">'+item.n+'</option>');
					});
				});

			document.querySelector("#prevtile")?.addEventListener("click", function(){
				var tileid = document.querySelector("#tileid").value;
				tileid -= 1;
				if(tileid < 0)
					tileid = 0;
				document.querySelector("#tileid").value = (tileid);
				document.querySelector("#tilepreview").setAttribute("src", "gfx/tile/"+tileid+".png");
			});
			document.querySelector("#nexttile")?.addEventListener("click", function(){
				var tileid = document.querySelector("#tileid").value;
				tileid = parseInt(tileid) + 1;
				document.querySelector("#tileid").value = (tileid);
				document.querySelector("#tilepreview").setAttribute("src", "gfx/tile/"+tileid+".png");
			});
		});
		</script> */}
				<div className="editortabs room-tab">
					<h2>Go to Room</h2>

					<select id="roomlist">
						<option value="0">Loading rooms</option>
					</select>
					<button id="warp">Warp</button>
					<br />
					<br />
				</div>
				<div className="editortabs user-tab">
					<h2>User actions</h2>
					<div>
						User:
						<br />
						<select id="user-tab-users"></select>
						<br />
						Custom code:
						<br />
						<textarea id="user-tab-code"></textarea>
						<br />
						<button id="user-tab-submit">Eval code</button>
					</div>
				</div>
				<div className="editortabs abilities-tab">
					<h2>Abilities editor</h2>

					<select id="allAbilities"></select>
					<button id="editSkill">Edit</button>
					<button id="newSkill">New</button>
					<button id="addUserSkill">Add to user</button>
					<button id="removeUserSkill">Remove from user</button>
					<button id="cloneSkill">Clone</button>
					<button id="testSkill">Test local</button>
					<hr />
					<form id="skilleditor-form" data-style="max-height: 450px; overflow: auto;"></form>
				</div>
			</div>
		</div>
	);
};
