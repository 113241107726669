import { Fragment, FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';
import { InformationBox, useLanguageContext } from '../components';

export const FkWantedPage: FunctionComponent = () => {
	const { inlineTranslation } = useLanguageContext();

	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					color="#ffa500"
					title={inlineTranslation({
						dk: 'Medlemmer til fk søges',
						no: 'Medlemmer til fk søkes',
						se: 'Medlemmar til fk sökes',
						en: 'Members to fk wanted',
					})}
					sections={[
						{
							content: inlineTranslation({
								dk: (
									<Fragment>
										<p>
											<b>Ansøgning til Festkomitéen:</b> <br />
											Festkomitéen er en gruppe med 4 brugere som arrangerer konkurrencer og events for Hundeparken.
											Brugerne som er i Festkomitéen skiftes ud efter at man har været med i 2 måneder. <br />
											<br />
											Når du søger om at blive en del af Festkomitéen skal du sammen med de andre medlemmer arrangere
											konkurrencer og events i 2 måneder. I perioden du er medlem vil halvdelen af gruppen byttes ud.{' '}
											<br />
											Fra dags dato skal du have Discord for at kunne kommunikere med de andre medlemmer. Det er ikke et
											krav at, du taler i voice med de andre medlemmer. <br />
											<br />
											<b>Hvad får man for at være med i Festkomitéen?</b>{' '}
										</p>
										<ul>
											<li>Festkomité-hat i ønsket farve/mønster (inden for den skabelon vi har)</li>{' '}
											<li>Snakkeboble med stærkere farve, som passer til din hundefarve</li>{' '}
											<li>FK-mærke ved siden af din rang</li>{' '}
											<li>
												Evner i form af arkitekt, color roulette spinning wheel og lignende (bliver fjernet, når man
												ikke længere er medlem i FK).
											</li>
										</ul>
										<p>
											{' '}
											<b>Løn:</b> <br />
											For at være med i 2 måneder får du i alt 10.000 kb (5.000 kb pr. måned) og 1 glas-stjerne i løn.{' '}
											<br />
											<br />
											<b>Tillægsinformation:</b> <br />
											Man kan blive udvalgt til at være medlem af Festkomitéen flere gange, selvom man har været det
											tidligere. <br />
											Alle ansøgninger bliver gemt og man kan sende den ind når som helst. <br />
											<br />
											Dette er noget vi prøver i en periode, for at give flere chancen for at være med til at arrangere
											konkurrencer - uden at det skal være bindende over en længere periode. Det giver jer også
											muligheden for at lære flere bedre at kende på kryds og tværs. <br />
											<br />
											<b>Din ansøgning skal indeholde:</b> <br />
											Hundenavn: <br />
											Brugernavn: <br />
											Ansøgningstekst: <br />
											<br />
											Send ansøgningen på mail til mail@hundeparken.net{' '}
										</p>
									</Fragment>
								),
								no: (
									<Fragment>
										<p>
											<b>Søknad til Festkomitéen:</b> <br />
											Festkomitéen er en gruppe med 4 brukere som arrangerer konkurranser og events for Hundeparken.
											Brukerne som er i Festkomitéen skiftes ut etter at man har vært med i 2 måneder. <br />
											<br />
											Når du søker til å bli med i Festkomitéen skal du sammen med de andre andre medlemmene arrangere
											konkurranser og events i 2 måneder. I perioden du er medlem vil halve gruppen byttes ut. <br />
											Per dags dato må du ha Discord for å kunne kommunisere med de andre medlemmene. <br />
											Det er ikke krav om at du må prate i voice med de andre. <br />
											<br />
											<b>Hva får man for å være med i Festkomitéen?</b>{' '}
										</p>
										<ul>
											<li>Festkomité-hatt i ønsket farge/mønster (innenfor malen vi har)</li>{' '}
											<li>Snakkeboble med sterkere farge som passer hundefargen din</li>{' '}
											<li>FK-merke ved siden av rangen</li>{' '}
											<li>
												Evner i form av arkitekt, color roulett spinning wheel o.l (blir fjernet når man ikke er med i
												FK lenger)
											</li>
										</ul>
										<p>
											{' '}
											<b>Lønn:</b> <br />
											For å være med 2 måneder får du totalt 10.000kb (5000kb pr måned) og 1 glass-stjerne i lønn.{' '}
											<br />
											<br />
											<b>Tilleggsinformasjon:</b> <br />
											Man kan bli valgt ut til å være med i Festkomitéen flere ganger, selv om man har vært valgt
											tidligere. <br />
											Alle søknader vil bli lagret, og man kan sende inn når som helst. <br />
											<br />
											Dette er noe vi vil prøve ut over en periode, for å gi fler sjansen til å være med på å arrangere
											konkurranser, uten å bli bindet over en lang periode. Det gir dere også muligheten til å bli kjent
											med hverandre på kryss og tvers. <br />
											<br />
											<b>Din søknad skal inneholde:</b> <br />
											Hundenavn: <br />
											Brukernavn: <br />
											Søknadstekst: <br />
											<br />
											Send informasjonen til mail@hundeparken.net{' '}
										</p>
									</Fragment>
								),
								se: (
									<Fragment>
										<p>
											<b>Ansökan till Festkommittén</b> <br />
											Festkommittén är en grupp med 4 medlemmar som arrangerar tävlingar och events i Hundparken.
											Medlemmarna som är med iFestkommittén byts ut efter 2 månader. <br />
											<br />
											När du ansöker om att vara en del av Festkommittén ska du tillsammans med de andra medlemmarna
											arrangera tävlingar och events i 2 månader. Under den period som du är medlem kommer halva gruppen
											att bytas ut. För att kunna kommunicera med de andra medlemmarna i gruppen krävs att du har
											Discord. Det är inte ett krav att du pratar i voice med de andra medlemmarna. <br />
											<br />
											<b>Vad får man för att vara med i Festkommittén?</b>{' '}
										</p>
										<ul>
											<li>Festkommitté-hatt i valfri färg/mönster (från den mall som vi har)</li>{' '}
											<li>Pratbubbla med starkare färg som passar din hundfärg</li> <li>FK-märke bredvid din rang</li>{' '}
											<li>
												Förmågor såsom arkitekt, color roulette, spinning wheel och liknande (tas bort när man inte
												längre är medlem i FK).
											</li>
										</ul>
										<p>
											<b>Lön:</b> <br />
											För att vara med i 2 månader får du totalt 10.000 kb (5.000 kb per månad) och 1 glas-stjärna i
											lön. <br />
											<br />
											<b>Tilläggsinformation:</b> <br />
											Man kan bli utvald till att vara medlem av Festkommittén flera gånger, även om man varit med
											tidigare. <br />
											Alla ansökningar sparas och man kan skicka in den när som helst. <br />
											<br />
											Detta är något som vi provar under en viss period, för att ge flera chansen att vara med och
											arrangera tävlingar - utan att det ska vara bindande över en längre period. Det ger er också
											möjlighet att lära känna flera medlemmar. <br />
											<br />
											<b>Din ansökan ska innehålla följande:</b> <br />
											Hundnamn: <br />
											Användarnamn: <br />
											Ansökningstext: <br />
											<br />
											Skicka din ansökan via mail till mail@hundeparken.net{' '}
										</p>
									</Fragment>
								),
								en: (
									<Fragment>
										<p>
											<b>Application to Festkomitéen:</b>
											<br />
											The Festkomité is a group of 4 users who organize competitions and events for Hundeparken. The
											users who are on the Festkomitéen are replaced after 2 months.
											<br />
											<br />
											When you apply to join the Festkomité, you will organize competitions and events for 2 months with
											the other members. During the period you are a member, half of the group will be replaced. <br />
											You must have Discord to be able to communicate with the other members. <br />
											There is no requirement that you have to speak in voice with the others. <br />
											<br />
											<b>What do you get for joining the Festkomité?</b>{' '}
										</p>
										<ul>
											<li>Your own hat in the desired color/pattern (within the template we have)</li>{' '}
											<li>Speech bubble with a stronger color that matches your dog's color</li>{' '}
											<li>FK badge next to the rank</li>{' '}
											<li>
												Skills in the form of the architect, color roulette spinning wheel etc. (This will be removed
												when you are no longer in FK)
											</li>
										</ul>
										<p>
											{' '}
											<b>Payment:</b> <br />
											For joining for 2 months, you get a total of 10,000kb (5,000kb per month) and 1 glass-star in
											salary. <br />
											<br />
											<b>Additional information:</b> <br />
											You can be selected to be part of the Festkomité several times, even if you have been selected
											previously. <br />
											All the applications will be saved. You can send your application whenever you want. <br />
											<br />
											This is something we will try out over a period of time, to give more users the chance to take
											part in organizing competitions, without being bound over a long period. It also gives you the
											opportunity to get to know each other crosswise. <br />
											<br />
											<b>Your application must contain:</b> <br />
											Dogname: <br />
											Username: <br />
											Application text:
											<br />
											<br />
											Send the information to mail@hundeparken.net{' '}
										</p>
									</Fragment>
								),
							}),
						},
					]}
				/>
			</div>
		</div>
	);
};
