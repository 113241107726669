import { useLanguageContext } from '../../components';
import { FunctionComponent, useEffect } from 'react';
import styles from './WhatIs.module.scss';

export const KobbelPage: FunctionComponent = () => {
	const { inlineTranslation, language } = useLanguageContext();

	useEffect(() => {
		document.title = inlineTranslation({
			dk: 'KOBBEL: Hvad er det?',
			no: 'KOBBEL: Hva er det?',
			se: 'KOPPEL: Vad är det?',
			en: 'KOBBEL: What is it?',
		});

		document.body.dataset.grid = 'no';
	});

	const ranks: { kobbel: string; description: string }[] = [
		{
			kobbel: 'banden',
			description: inlineTranslation({
				dk: 'Banden',
				no: 'Banden',
				se: 'Bandet',
				en: 'The gang',
			}),
		},
		{
			kobbel: 'stammen',
			description: inlineTranslation({
				dk: 'Stammen',
				no: 'Stammen',
				se: 'Stammen',
				en: 'The tribe',
			}),
		},
		{
			kobbel: 'klanen',
			description: inlineTranslation({
				dk: 'Klanen',
				no: 'Klanen',
				se: 'Klanen',
				en: 'The clan',
			}),
		},
		{
			kobbel: 'sekten',
			description: inlineTranslation({
				dk: 'Sekten',
				no: 'Sekten',
				se: 'Sekten',
				en: 'The sect',
			}),
		},
		{
			kobbel: 'logen',
			description: inlineTranslation({
				dk: 'Logen',
				no: 'Lojsen',
				se: 'Logen',
				en: 'The lodge',
			}),
		},
		{
			kobbel: 'selskabet',
			description: inlineTranslation({
				dk: 'Selskabet',
				no: 'Selskapet',
				se: 'Sällskapet',
				en: 'The company',
			}),
		},
	];

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img src={`/images/whatis/kobbel-${language}.png`} alt="Header" />
			</div>
			<div className={styles.info}>
				{inlineTranslation({
					dk: 'Hunde bliver klogere med tiden og lærer hele tiden nye tricks. Jo klogere man er - jo højere rang har man i hundeparken, f.eks kan man først skide når man er sporhund og svømme når man er blevet vandhund. Her er en oversigt over range:',
					no: 'Hunder blir klokere med tiden og lærer hele tiden nye triks. Jo klokere man er jo høyere rang har man i hundeparken. Her er en oversikt over rangordningen:',
					se: 'Hundar blir med tiden klokare och lär sig ständigt nya tricks. Desto klokare en hund är desto högre rang har hunden i Hundparken. Här är en översikt över Hundparkens rangsystem:',
					en: 'Hunde bliver klogere med tiden og lærer hele tiden nye tricks. Jo klogere man er - jo højere rang har man i hundeparken, f.eks kan man først skide når man er sporhund og svømme når man er blevet vandhund. Her er en oversigt over range:',
				})}
			</div>
			{ranks.map(kobbelInfo => (
				<div className={styles.rang} key={kobbelInfo.kobbel}>
					<div className={styles.img}>
						<img src={`/images/whatis/${kobbelInfo.kobbel}.png?3`} alt={kobbelInfo.kobbel} />
					</div>
					<div className={styles.desc}>
						<div className={styles.title}>{kobbelInfo.kobbel}</div>
					</div>
				</div>
			))}
		</div>
	);
};
