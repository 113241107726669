import { FunctionComponent } from 'react';
import styles from '../Otherpages.module.scss';
import { InformationBox, Spinner, GlitchyText } from '../../components';
import { useGetAnnouncementQuery } from './Announcement.graphql';
import { useParams } from 'react-router-dom';

export const AnnouncementPage: FunctionComponent = () => {
	const { id } = useParams();
	const { data } = useGetAnnouncementQuery({
		variables: {
			id: parseInt(id || ''),
		},
	});
	const announcement = data?.getAnnouncement;

	if (!announcement) {
		return (
			<div className={styles.page}>
				<div className={styles.container} style={{ justifyContent: 'center', display: 'flex' }}>
					<Spinner />
				</div>
			</div>
		);
	}

	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					color="#ef0080"
					title={<GlitchyText text={announcement.title || ''} />}
					sections={[
						{
							content: (
								<div>
									{announcement.image && (
										<img src={announcement.image} style={{ maxWidth: '80px', float: 'right' }} alt="" />
									)}
									<GlitchyText text={announcement.message} />
									<div style={{ display: 'flex', gap: '10px', padding: '10px 0px' }}>
										{(announcement.reactions ?? []).map(announcementReaction => (
											<div style={{ display: 'flex', gap: '3px' }}>
												<img src={`/images/smiley/${announcementReaction.reaction}.png`} alt="" />
												{announcementReaction.count}{' '}
											</div>
										))}
									</div>
								</div>
							),
						},
					]}
				/>
			</div>
		</div>
	);
};
