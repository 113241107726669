import { FunctionComponent, ReactNode } from 'react';
import styles from './InformationBox.module.scss';
import classNames from 'classnames';

export type InformationBoxSection = {
	title?: string | ReactNode;
	id?: string;
	titleColor?: InformationBoxSectionColor;
	content: ReactNode;
};

export type InformationBoxSectionColor = 'yellow' | 'pink' | 'lightgreen' | 'lightpurple';

export const InformationBox: FunctionComponent<{
	id?: string;
	color: string;
	title: string | JSX.Element;
	icon?: string;
	subBox?: boolean;
	sections: InformationBoxSection[];
}> = props => {
	return (
		<div id={props.id} className={classNames(styles.container, { [styles.small]: props.subBox })}>
			<div className={styles.mainTitle} style={{ backgroundColor: props.color }}>
				<div className={styles.mainTitleText}>{props.title}</div>
				{props.icon && (
					<img src={props.icon} alt={typeof props.title === 'string' ? props.title : ''} className={styles.icon} />
				)}
			</div>
			{props.sections.map((section, index) => (
				<div key={index}>
					{section.title && (
						<div
							id={section.id}
							className={classNames(styles.title, {
								[styles.lightGreen]: section.titleColor === 'lightgreen',
								[styles.pink]: section.titleColor === 'pink',
								[styles.lightPurple]: section.titleColor === 'lightpurple',
							})}>
							{section.title}
						</div>
					)}
					<div className={styles.content}>{section.content}</div>
				</div>
			))}
		</div>
	);
};
