import { Fragment, FunctionComponent } from 'react';
import style from './IntroductionBox.module.scss';
import { useLanguageContext } from '../Language';
import { useSeason } from '..';

export const IntroductionBox: FunctionComponent = () => {
	const { season, inlineSeason } = useSeason();
	const { getTranslation, inlineTranslation } = useLanguageContext();
	return (
		<div className={style.introduction} data-season={season}>
			<div className={style.container}>
				<div className={style.title}>
					{inlineTranslation({
						dk: (
							<Fragment>
								<span className={style.mainTitle}>VELKOMMEN</span> til HUNDEPARKEN
							</Fragment>
						),
						no: (
							<Fragment>
								<span className={style.mainTitle}>VELKOMMEN</span> til HUNDEPARKEN
							</Fragment>
						),
						se: (
							<Fragment>
								<span className={style.mainTitle}>VÄLKOMMEN</span> til HUNDEPARKEN
							</Fragment>
						),
						en: (
							<Fragment>
								<span className={style.mainTitle}>WELCOME</span> to HUNDEPARKEN
							</Fragment>
						),
					})}
				</div>
				<div className={style.body} dangerouslySetInnerHTML={{ __html: getTranslation('introduction_message') }} />
			</div>
			<div className={style.speechTriangle} />
			<img
				src={inlineSeason({
					normal: '/images/grass-tile-fryd.gif',
					autumn: '/images/grass-tile-fryd-autumn.gif',
					winter: '/images/grass-tile-fryd-winter.gif',
				})}
				alt=""
			/>
		</div>
	);
};
