import { useLanguageContext } from '../../components';
import { FunctionComponent, useEffect } from 'react';
import styles from './WhatIs.module.scss';

export const AgePage: FunctionComponent = () => {
	const { inlineTranslation, language } = useLanguageContext();

	useEffect(() => {
		document.title = inlineTranslation({
			dk: 'ALDER: Hvad er det?',
			no: 'ALDER: Hva er det?',
			se: 'HUNDÅR: Vad är det?',
			en: 'ALDER: What is it?',
		});

		document.body.dataset.grid = 'no';
	});

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img src={`/images/whatis/alder-${language}.png`} alt="Header" />
			</div>
			<div className={styles.info}>
				{inlineTranslation({
					dk: (
						<>
							Et menneskeår svarer til syv hundeår, og hunde har derfor fødselsdag syv gange om året...
							<br />
							<br />
							<br />- Det svarer til hver 52. dag, hvorfor der altid er hunde, der holder fødselsdagsfest i Parken!
							<br />
							<br />
							<br />
							Hunde elsker at få gaver - især til deres fødselsdag - så husk at have en gave parat, når du støder på en
							fødselar.
						</>
					),
					no: (
						<>
							Et menneskeår tilsvarer sju hundeår og hunder har derfor fødselsdag sju ganger i året.
							<br />
							<br />
							<br />
							Det tilsvarer hver 52. dag, derfor er det alltid hunder som har bursdag i Hundeparken!
							<br />
							<br />
							<br />
							Hunder elsker å få gaver, spesielt på bursdagen sin - så husk å ha en bursdagsgave klar når du møter på et
							bursdagsbarn. Du kan se om en hund har fødselsdag ved at den har et lite kakestykke ved siden av alderen
							sin.
						</>
					),
					se: (
						<>
							Ett människoår motsvarar 7 hundår, viket innebär att hundar fyller år 7 gånger per år.
							<br />
							<br />
							<br />
							Med andra ord är det alltid någon hund som ställer till med födelsedagsfest i parken. Alla hundar älskar
							att få presenter – särskilt födelsedagspresenter – så se till att ha en present till hands ifall att du
							skulle stöta på en hund som fyller år.{' '}
						</>
					),
					en: (
						<>
							A human year corresponds to 7 dog years and dogs therefore celebrate their birthday 7 times a year. <br />{' '}
							<br /> <br />
							This corresponds to every 52 days, which is why there are always dogs throwing birthday parties in the
							park! <br /> <br /> <br /> Dogs love to get presents - especially for their birthday - so remember to have
							a gift ready for when you encounter a dog celebrating their birthday. <br />
							You can tell by them having av small piece of cake next to their age.
						</>
					),
				})}
			</div>
		</div>
	);
};
