import { FunctionComponent } from 'react';
import style from './HeaderedButton.module.scss';

export const HeaderedButton: FunctionComponent<{
	title: string[];
	image: string;
	color: string;
	hoverColor: string;
}> = props => {
	return (
		<div
			className={style.container}
			style={{ '--mainColor': props.color, '--hoverColor': props.hoverColor } as React.CSSProperties}>
			<div className={style.title}>
				{props.title.map((title, index) => (
					<div key={index}>{title}</div>
				))}
			</div>
			<div className={style.body}>
				<img src={props.image} alt="" />
			</div>
		</div>
	);
};
