import { FunctionComponent, PropsWithChildren } from 'react';
import style from './ColoredBox.module.scss';

export const ColoredBox: FunctionComponent<
	PropsWithChildren<{
		title: string;
		color: string;
	}>
> = props => {
	return (
		<div className={style.container} style={{ '--color': props.color } as React.CSSProperties}>
			<div className={style.title}>{props.title}</div>
			<div className={style.body}>{props.children}</div>
		</div>
	);
};
