import React, { Fragment } from 'react';
import { Lang } from './utils.js';
import { Link } from 'react-router-dom';

export const translations = {
	test: {
		dk: '',
		no: '',
		se: '',
		en: '',
	},
	CREDITS_INTRODUCTION: {
		dk: (
			<Fragment>
				<b>Hvem er med til at støtte Parken?</b>
				<br />
				Vi er så heldige at vi har nogle dygtige hunde som bidrager til at gøre Hundeparken til et endu federe og bedre
				sted. Vi mener at disse hunde fortjener extra anerkendelse.
				<br />
				Samtidigt vil vi takke DR, NRK og SVT for at udvikle og lave det orginale Hundeparken grafik.
			</Fragment>
		),
		no: (
			<Fragment>
				<b>Hvem er parkens støttespillere?</b>
				<br />
				i er så heldige at vi har noen flinke hunder som bidrar til å gjøre Hundeparken til et enda kulere og bedre
				sted. Vi mener de fortjener en ekstra anerkjennelse.
				<br />
				Samtidig vil vi rette en takk til DR, NRK og SVT for å utvikle og lage den originale hundeparken-grafikken.
			</Fragment>
		),
		se: (
			<Fragment>
				<b>Vilka är parkens stöttepelare?</b>
				<br />
				Vi är så lyckliga över att vi har några duktiga hundar som bidrar till att göra Hundeparken till en ännu
				roligare och bättre plats. Vi tycker att dessa förtjänar att bli uppmärksammade lite extra.
				<br />
				Samtidigt vill vi rikta ett tack till DR, NRK och SVT för att ha utvecklat Hundeparkens original grafik.
			</Fragment>
		),
		en: (
			<Fragment>
				<b>Who's the park's supporters?</b>
				<br />
				We are lucky to have some talented dogs which contributes to make Hundeparken a better place. We think these
				dogs deserve extra recognition.
				<br />
				At the same time, we would like to thank DR, NRK and SVT for developing and creating the original Hundeparken
				graphics.
			</Fragment>
		),
	},
	CREDITS_DESIGNER: {
		dk: 'Medlem af Hundeparkens officielle designteam.',
		no: 'Medlem av hundeparkens offisielle designteam.',
		se: 'Medlem av Hundparkens officiella designteam.',
		en: 'Member of Hundeparken’s official design-team.',
	},
	CREDITS_PARTY_COMITEE: {
		dk: 'Medlem af Festkomitéen i Hundeparken.',
		no: 'Medlem av Festkomitéen i Hundeparken.',
		se: 'Medlem av Festkommittén i Hundparken.',
		en: 'Member of the Party Comittee in Hundeparken.',
	},
	CREDITS_SOCIAL_MEDIA: {
		dk: 'Ansvarlig for Hundeparkens sociale medier',
		no: 'Ansvarlig for Hundeparkens sosiale medier',
		se: 'Ansvarig för Hundeparkens sociala medier',
		en: "Responsible for Hundeparken's social media",
	},
	CREDITS_CLIENT_DEV: {
		dk: 'Client developer og en del af Hundeparkens udviklingsteam',
		no: 'Client developer og en del av Hundeparkens utviklingsteam',
		se: 'Client developer och en del av Hundeparkens utvecklingsteam',
		en: "Client developer and part of Hundeparken's development team",
	},
	TEAM_MENU_TITLE: {
		dk: 'Oversigt',
		no: 'Oversikt',
		se: 'Översikt',
		en: 'Overview',
	},
	TEAM_DEV_TITLE: {
		dk: 'Udvikler',
		en: 'Developer',
		no: 'Utvikler',
		se: 'Utvecklare',
	},
	TEAM_DEV: {
		dk: (
			<Fragment>
				En udvikler står for udvikling og den tekniske drift af Hundeparken. Han sørger for, at alt det tekniske
				fungerer optimalt, og at Parken får nye og bedre funktioner.
				<br />
				<br />
				Hvordan genkender jeg en udvikler?
				<br />
				En udvikler kendetegnes ved et blåt våbenskjold{' '}
				<img style={{ display: 'inline' }} src="images/dev-mini.png" alt="udvikler bagde" /> ved siden af sin rang i
				Parken. Han har en anderledes pelsfarve (betafarve), så du har lettere ved at få øje på og genkende ham.
				<br />
				<br />
				Nedenunder ser du Parkens udvikler:
			</Fragment>
		),
		en: (
			<Fragment>
				The developer is responsible for the development and technical operation of Hundeparken. He is the one who makes
				sure that all the technological aspects work optimally, and he supplies the park with new and better functions.
				<br />
				<br />
				How do I recognise the developer?
				<br />
				The developer is characterized by the fact that he has a blue coat of arms{' '}
				<img style={{ display: 'inline' }} src="images/dev-mini.png" alt="developer bagde" /> next to his rank in the
				park. He also has a slightly different coat colour (beta colour), so that he’s easier to spot and recognise.
				<br />
				<br />
				Below you can see who is the park’s developer.
			</Fragment>
		),
		no: (
			<Fragment>
				Utvikleren står for utvikling og teknisk drift av Hundeparken. Det er han som sørger for at alt det tekniske
				fungerer optimalt og at parken hele tiden får nye og bedre funksjoner.
				<br />
				<br />
				Hvordan gjenkjenne en utvikler?
				<br />
				Utvikleren kjennetegnes ved at han har et blått våpenskjold{' '}
				<img style={{ display: 'inline' }} src="images/dev-mini.png" alt="Utvikler våpenskjold" /> ved siden av rangen
				sin i parken. Han har også en litt annerledes pelsfarge (betafarge) slik at han skal være lettere å få øye på og
				kjenne igjen.
				<br />
				<br />
				Nedenfor ser du hvem som er parkens utvikler.
			</Fragment>
		),
		se: (
			<Fragment>
				Utvecklare står för utveckling och teknisk drift av Hundparken. Det är han som ser till att allt det tekniska
				fungerar optimalt och att parken hela tiden får nya och bättre funktioner.
				<br />
				<br />
				Hur känner jag igen en utvecklare?
				<br />
				Utvecklaren känner du igen på hans blåa vapensköld{' '}
				<img style={{ display: 'inline' }} src="images/dev-mini.png" alt="Utvecklare vapensköld" /> vid sidan av sin
				rang i parken. Han har också en lite annorlunda pälsfärg (betafärg) så att han lättare går få syn på och känna
				igen.
				<br />
				<br />
				Nedanför ser du vem som är parkens utvecklare.
			</Fragment>
		),
	},
	TEAM_ADMIN_TITLE: {
		dk: 'Administratorer',
		en: 'Administrators',
		no: 'Administratorer',
		se: 'Administratörer',
	},
	TEAM_ADMIN: {
		dk: (
			<Fragment>
				Administratorerne både ejer og sørger for den daglige drift af Hundeparken. <br />
				Det er dem, som er de øverste ansvarlige, og som har det sidste ord i alle afgørelser i Hundeparken.
				<br />
				<br />
				Hvordan genkender jeg en administrator? <br />
				En administrator kendetegnes ved et gult skjold{' '}
				<img style={{ display: 'inline' }} src="images/admin-mini.png" alt="administrator skjold" /> ved siden af sin
				rang i Parken. Desuden har personen en anderledes pelsfarve (betafarve), så du har lettere ved at få øje på og
				genkende vedkommende.
				<br />
				<br />
				Nedenunder ser du Parkens administratorer:
			</Fragment>
		),
		no: (
			<Fragment>
				Administratorene både eier og sørger for den daglige driften av Hundeparken.
				<br />
				Det er de som har det øverste ansvaret og siste ordet i alle avgjørelser.
				<br />
				<br />
				Hvordan gjenkjenner jeg en administrator?
				<br />
				En administrator kjennetegnes ved at de har et gult våpenskjold{' '}
				<img style={{ display: 'inline' }} src="images/admin-mini.png" alt="administrator våpenskjold" /> ved siden av
				rangen sin i parken. I tillegg har de en litt annerledes pelsfarge (betafarge) slik at de er lettere å få øye på
				og kjenne igjen.
				<br />
				<br />
				Nedenfor ser du parkens administratorer.
			</Fragment>
		),
		se: (
			<Fragment>
				Administratörerna både äger och har hand om den dagliga driften av Hundparken.
				<br />
				Det är de som har det högsta ansvaret och det sista ordet i alla beslut.
				<br />
				<br />
				Hur känner jag igen en administratör?
				<br />
				En administratör känner du igen på deras gula vapensköld{' '}
				<img style={{ display: 'inline' }} src="images/admin-mini.png" alt="administratör vapensköld" /> vid sidan av
				sin rang i parken. I tillägg har de en lite annorlunda pälsfärg (betafärg) så att de lättare går få syn på och
				känna igen.
				<br />
				<br />
				Nedanför ser du parkens administratörer:
			</Fragment>
		),
		en: (
			<Fragment>
				The Administrators both own and take care of the daily operations of Hundeparken.
				<br />
				They are the ones who have the highest responsibility, and the last word in all decisions.
				<br />
				<br />
				How do I recognise an administrator?
				<br />
				An administrator is distinguishable by the fact that they have a yellow coat of arms{' '}
				<img style={{ display: 'inline' }} src="images/admin-mini.png" alt="Administrator bagde" /> next to their rank
				in the park. In addition, they have a slightly different coat colour (beta colour), so that they are easier to
				spot and recognise.
				<br />
				<br />
				Below you can see the park's administrators.
			</Fragment>
		),
	},
	TEAM_MODERATOR_TITLE: {
		dk: 'Moderatorer',
		en: 'Moderators',
		no: 'Moderatorer',
		se: 'Moderatorer',
	},
	TEAM_MODERATOR: {
		dk: (
			<Fragment>
				Moderatorer er personer, som arbejder frivilligt for Hundeparken.
				<br />
				Deres opgave er, at sørge for at Hundeparken er et trygt sted at være for alle brugerne, som er der. De har
				evnen til at spidde og smide hunde ud, som ikke følger reglerne i kortere eller længere perioder. Moderatorerne
				er underlagt administratorerne.
				<br />
				<br />
				Hvordan genkender jeg en moderator?
				<br />
				En moderator kendetegnes ved et rødt våbenskjold{' '}
				<img style={{ display: 'inline' }} src="images/mod-mini.png" alt="moderator våbenskjold" /> ved siden af sin
				rang i Parken. De kan også have en anderledes pelsfarve (betafarve), så du har lettere ved at få øje på og
				genkende dem.
				<br />
				<br />
				Nedenunder ser du Parkens nuværende moderatorer:
			</Fragment>
		),
		no: (
			<Fragment>
				Moderatorene er personer som jobber frivillig for Hundeparken.
				<br />
				Deres oppgave er å sørge for at Hundeparken er et trygt sted å være for alle brukerne som er der. De har evnen
				til å spidde og kicke ut hunder som ikke oppfører seg i kortere eller lengre perioder. Moderatorene er underlagt
				administratorene.
				<br />
				<br />
				Hvordan gjenkjenner jeg en moderator?
				<br />
				En moderator kjennetegnes ved at de har et rødt våpenskjold{' '}
				<img style={{ display: 'inline' }} src="images/mod-mini.png" alt="moderator våpenskjold" /> ved siden av rangen
				sin i parken. Noen av dem har også en litt annerledes pelsfarge (betafarge) slik at de er lettere å få øye på og
				kjenne igjen.
				<br />
				<br />
				Nedenfor ser du hvilke hunder som er moderatorer i parken nå.
			</Fragment>
		),
		se: (
			<Fragment>
				Moderatorer är personer som frivilligt jobbar för Hundparken.
				<br />
				Deras uppgift är att se till att Hundparken är ett tryggt ställe att vara på för alla användare. De har
				möjlighet att spetsa och kicka ut hundar som inte uppför sig, i kortare eller längre perioder. Moderatorerna
				jobbar för administratörerna.
				<br />
				<br />
				Hur känner jag igen en moderator?
				<br />
				En moderator känner du igen på deras röda vapensköld{' '}
				<img style={{ display: 'inline' }} src="images/mod-mini.png" alt="moderator vapensköld" /> vid sidan av sin rang
				i parken. Några av dem har också en lite annorlunda pälsfärg (betafärg) så att de lättare går få syn på och
				känna igen.
				<br />
				<br />
				Nedanför ser du vilka hundar som är moderatorer i parken nu.
			</Fragment>
		),
		en: (
			<Fragment>
				The moderatos are people who work voluntarily for Hundeparken, they are chosen by admins and other moderators.
				Their task is to make sure that Hundeparken is a safe space for all its users. They have the ability to spear
				and kick out dogs who misbehave in shorter and longer periods of time. The moderators are subordinate to the
				administrators.
				<br />
				<br />
				How do I recognise a moderator?
				<br />A moderator is characterized through the fact that they have a red coat of arms{' '}
				<img style={{ display: 'inline' }} src="images/mod-mini.png" alt="moderator arms" /> next to their rank in the
				park. Some of them also choose to have a slightly different coat colour (beta colour), so that they are easier
				to spot and recognise.
				<br />
				<br />
				Below you can see which dogs are moderators in the park now.
			</Fragment>
		),
	},
	introduction_message: {
		no: 'Spillet er fortsatt under utvikling, men du kan allerede nå skaffe deg din helt egen pixelhund! <br/> Fortsatt gratis og reklamefritt! <br/><br/>Vi anbefaler dessuten at du blir med i vår discord-server for å holde deg oppdatert på alt av siste nytt! W0of!',
		se: 'Spelet är fortfarande under utveckling, men du kan redan nu skaffa dig en alldeles egen pixelhund!<br/>Fortfarande gratis och helt utan reklam!<br/><br/> Dessutom, rekommenderar vi att du går med i vår Discord-server så du kan hålla dig uppdaterad på senaste nytt! <br/>W0of!',
		dk: 'Spillet er fortsat under udvikling, men du kan allerede nu skaffe dig din helt egen pixelhund!  <br/> Fortsat gratis og reklamefrit. <br/><br/> Vi anbefaler desuden, at du melder dig ind i vores Discord server, så du kan være opdateret på alt af seneste nyt! <br/>W0of!',
		en: 'Game is still under development, but you can already now make your very own pixeldog!<br/> Still free from ads and transactions!<br/><br/>We also recommend that you join our discord server to stay up to date on all the latest news! <br/>W0of!',
	},
	HIGHSCORE_BOX: {
		dk: (
			<Fragment>
				<Link to="/highscore">
					<u>Klik her</u>
				</Link>{' '}
				for at se lederne af hvert minispil i Hundeparken.
			</Fragment>
		),
		no: (
			<Fragment>
				<Link to="/highscore">
					<u>Klikk her</u>
				</Link>{' '}
				for å se lederne for hvert minispill i Hundeparken.
			</Fragment>
		),
		se: (
			<Fragment>
				<Link to="/highscore">
					<u>Klicka här</u>
				</Link>{' '}
				för att se ledarna för varje minispel i Hundeparken.
			</Fragment>
		),
		en: (
			<Fragment>
				<Link to="/highscore">
					<u>Click here</u>
				</Link>{' '}
				to see the leaders of each minigame in Hundeparken.
			</Fragment>
		),
	},
	FK_BOX_TITLE: {
		dk: 'Kunne du tænke dig at blive medlem af Festkomitéen?',
		no: 'Kunne du tenke deg å bli medlem i Festkomitéen?',
		se: 'Skulle du kunna tänka dig att bli medlem i Festkomitéen?',
		en: 'Would you consider becoming a member of the Party Committee?',
	},
	FK_BOX_DESC: {
		dk: (
			<Fragment>
				<Link to="/fk-wanted">
					<u>Klik her</u>
				</Link>{' '}
				for at læse mere om stillingen, og hvordan du går frem til at sende din ansøgning.
			</Fragment>
		),
		no: (
			<Fragment>
				<Link to="/fk-wanted">
					<u>Klikk her</u>
				</Link>{' '}
				for å lese mer om stillingen, og hvordan du går frem for å sende din søknad.
			</Fragment>
		),
		se: (
			<Fragment>
				<Link to="/fk-wanted">
					<u>Klicka här</u>
				</Link>{' '}
				för att läsa mer om tjänsten, och hur du går tillväga för att skicka din ansökan.
			</Fragment>
		),
		en: (
			<Fragment>
				<Link to="/fk-wanted">
					<u>Click here</u>
				</Link>{' '}
				to read more about the position, and how to go about sending your application.
			</Fragment>
		),
	},
} satisfies { [TranslationKey: string]: { [Language in Lang]: string | React.ReactNode } };
