import { FunctionComponent } from 'react';
import { ColoredBox, useLanguageContext } from '..';
import styles from './MerchBox.module.scss';

export const MerchBox: FunctionComponent = () => {
	const { inlineTranslation } = useLanguageContext();

	return (
		<div className={styles.container}>
			<img src="/images/header/merch.png" alt="" />
			<ColoredBox
				color="#ef4680"
				title={inlineTranslation({
					dk: 'Ønsker du at være med til at støtte Hundeparken?',
					en: 'Do you want to help support Hundeparken?',
					no: 'Ønsker du å være med å støtte Hundeparken?',
					se: 'Vill du vara med och stödja Hundeparken?',
				})}>
				{inlineTranslation({
					dk: (
						<>
							Alle indtægerne Alle de indtægter, vi får via salget af HP Merch fra Firstgrade.dk, går til drift og
							videreudvikling af spillet. <br />
							Besøg vores webshop{' '}
							<a href="https://firstgrade.dk/collections/hundeparken" target="_blank" rel="noreferrer nofollow">
								her
							</a>
							.
						</>
					),
					en: (
						<>
							All the income we receive via the sale of HP Merch from Firstgrade.dk will go towards the operation and
							further development of the game. <br />
							Visit our webshop{' '}
							<a href="https://firstgrade.dk/collections/hundeparken" target="_blank" rel="noreferrer nofollow">
								here
							</a>
							.
						</>
					),
					no: (
						<>
							Alle inntektene vi får inn via salg av HP Merch fra Firstgrade.dk vil gå til drift og videreutvikling av
							spillet. <br />
							Besøk vår nettbutikk{' '}
							<a href="https://firstgrade.dk/collections/hundeparken" target="_blank" rel="noreferrer nofollow">
								her
							</a>
							.
						</>
					),
					se: (
						<>
							Alle inntektene vi får inn via salg av HP Merch fra Firstgrade.dk vil gå til drift og videreutvikling av
							spillet. <br />
							Besøk vår nettbutikk{' '}
							<a href="https://firstgrade.dk/collections/hundeparken" target="_blank" rel="noreferrer nofollow">
								her
							</a>
							.
						</>
					),
				})}
			</ColoredBox>
		</div>
	);
};
