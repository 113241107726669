import { InformationBox, InformationBoxSection, capitalize, useLanguageContext } from '../components';
import { FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';
import { Link } from 'react-router-dom';

const GENERAL_INFO = {
	dk: (
		<ul>
			<li>
				Der skal ikke diskuteres enkeltpersoner eller enkeltsager. Dette gælder både bagtalelse og i forbindelse med
				bestemte situationer.
			</li>
			<li>
				Mobning, spred af falske rygter om andre, chikane, diskriminerende eller anden stødende opførsel og sprogbrug er
				ikke tilladt.
			</li>
			<li>
				Alle skal føle sig velkommen. Undgå ytringer som krænker brugere i forhold til etnicitet, tro, politisk
				standpunkt eller tilsvarende. Brug din sunde fornuft og hav en normal og god opførsel. Ved stødende, eller
				misvisende opførsel omkring ovennævnte punkter, modtager man en advarsel og risikerer at få sin hund og bruger
				slettet.
			</li>{' '}
			<li>Opførsel eller samtaler som kan tolkes som stødende er uacceptabelt.</li>
			<li>Samtaler vedrørende sex:</li>
			<ul>
				<li>Opførsel som kan være stødende for børn er forbudt.</li>
				<li>Snak om sex er forbudt, med mindre der bliver taget hensyn til andre, og emnet ikke er stødende.</li>
			</ul>
			<li>
				Hundeparken er et spil beregnet til unge og voksne, men der skal være plads til alle. Vi ønsker derfor, at der
				bliver taget hensyn til de yngre, som også kommer for at spille.
			</li>
			<li>Spam:</li>
			<ul>
				<li>
					Det er ikke tilladt at spamme i Parken. Det vil sige, at du ikke må gentage samme besked flere gange i træk.
					Det samme gælder at gø og prutte flere gange i en længere periode.
				</li>
			</ul>
		</ul>
	),
	no: (
		<ul>
			<li>
				Det skal ikke diskuteres enkeltpersoner eller enkeltsaker. Dette gjelder både baksnakking, og i forbindelse med
				situasjoner.
			</li>
			<li>
				Mobbing, spre falske rykter om andre, trakassering, diskriminerende eller annen støtende oppførsel og språkbruk
				er ikke lov.
			</li>
			<li>
				Alle skal føle seg velkommen. Unngå ytringer som krenker andre brukere i forhold til etnisitet, legning,
				politisk standpunkt eller tilsvarende. Bruk vett og utvis normal god oppførsel. Ved støtende, eller misvisende
				oppførsel rundt punktene nevnt ovenfor, mottar man advarsel og risikerer å få hunden og brukeren sin slettet.
			</li>{' '}
			<li>Oppførsel eller samtaler som kan tolkes som støtende er ikke akseptabel.</li>
			<li>Sex-snakk:</li>
			<ul>
				<li>Oppførsel som kan være støtende for barn er forbudt.</li>
				<li>
					Snakk om sex er forbudt, med mindre det blir snakket om på en måte hvor man viser hensyn til andre, og ikke er
					støtende.
				</li>
			</ul>
			<li>
				Hundeparken er et spill beregnet for ungdom og voksne, men det skal være plass til alle. Vi ønsker derfor at det
				skal taes hensyn til de yngre som kommer også.
			</li>
			<li>Spam:</li>
			<ul>
				<li>
					Det er ikke lov å spamme i parken. Det betyr å gjenta samme beskjed flere ganger etter hverandre. Det samme
					gjelder bjeffing og promping over lang tid.
				</li>
			</ul>
		</ul>
	),
	se: (
		<ul>
			<li>
				Det ska inte diskuteras enskilda personer eller enskilda saker. Detta gäller baktalande och i samband med
				situationer.
			</li>
			<li>
				Mobbning, sprida falska rykten om andra, trakassering, diskriminerande eller annat stötande uppförande och
				språkbruk är inte tillåtet.
			</li>
			<li>
				Alla ska känna sig välkomna. Undvik yttranden som kränker andra medlemmar i förhållande till etnicitet,
				läggning, politisk ståndpunkt eller liknande. Använd vett och visa gott uppförande. Vid stötande eller
				missvisande uppförande vad gäller nämnda punkter kommer du motta en varning, och du riskerar att få din hund
				samt användare raderad.
			</li>{' '}
			<li>Uppförande eller samtal som kan tolkas som stötande är inte acceptabelt.</li>
			<li>Sex-snack:</li>
			<ul>
				<li>Uppförande som kan vara stötande för barn är förbjudet.</li>
				<li>
					Snack om sex är förbjudet, om det inte kan ske på ett sätt där man visar hänsyn till andra, och inte är
					stötande.
				</li>
			</ul>
			<li>
				Hundparken är ett spel ägnat för ungdomar och vuxna, men det ska finnas plats för alla. Vi önskar därför att det
				tas hänsyn till de yngre som också kan komma att spela.
			</li>
			<li>Spam:</li>
			<ul>
				<li>
					Det är inte tillåtet att spamma i parken. Det betyder att upprepa samma meddelande flera gånger efter
					varandra. Samma sak gäller skällande och pruttande under en längre stund.
				</li>
			</ul>
		</ul>
	),
	en: (
		<ul>
			<li>
				Do not discuss individual people or individual cases. This includes any type of slandering relating to specific
				incidents and the people involved.
			</li>
			<li>
				Bullying, spreading false rumors about others, harassment, discriminatory or other offensive behavior and
				language is not allowed.
			</li>
			<li>
				Hundeparken is welcoming to everyone. Have common sense and avoid offensive statements with regards to
				ethnicity, orientation, political views or similar sensitive topics. Failure to adhere to the terms of this rule
				will result in warnings. Recurring offensive or otherwise misleading behavior may lead to user termination.
			</li>{' '}
			<li>Behaviour or conversations that can be interpreted as offensive are not acceptable.</li>
			<li>Sex-talk:</li>
			<ul>
				<li>Behaviour that may be offensive to children is prohibited.</li>
				<li>
					Talking about sex is forbidden, unless it is talked about in a way that shows consideration for others, and is
					not offensive.
				</li>
			</ul>
			<li>
				Hundeparken is a game designed for teens and adults, but everyone is welcome. We, therefore, request that
				younger players are taken into consideration as well.
			</li>
			<li>Spam:</li>
			<ul>
				<li>
					Spamming is not allowed in the park. This means repeating the same message several times. The same goes for
					barking and farting several times.
				</li>
			</ul>
		</ul>
	),
};

const PERSONAL_INFO = {
	dk: (
		<ul>
			<li>Du bør ikke give personlig kontaktinformation ud om dig selv i Parken. Det kan for eksempel være:</li>
			<ul>
				<li>E-mail</li>
				<li>Telefonnummer</li>
				<li>Efternavn</li>
				<li>Adresse</li>
				<li>Andre private informationer</li>
			</ul>
			<li>
				Vælger du alligevel at dele ud privat information offentligt i Hundeparken, er det på dit eget ansvar. <br />
				Husk, at alle kan læse, hvad du skriver i Parken.
			</li>
			<li>Det er forbudt at dele andre brugeres personlige oplysninger i parken.</li>
		</ul>
	),
	no: (
		<ul>
			<li>Ikke gi ut personlig kontaktinformasjon i parken. Dette kan for eksempel være:</li>
			<ul>
				<li>E-postadresse</li>
				<li>Telefonnummer</li>
				<li>Etternavn</li>
				<li>Adresse</li>
				<li>Andre private applikasjoner</li>
			</ul>
			<li>
				Dersom du allikevel velger å gi ut personlig informasjon offentlig blir det gjort på eget ansvar. <br />
				Husk at alle kan lese hva du skriver i parken.
			</li>
			<li>Det er ikke lov å dele andre brukere sin personlig informasjon i parken.</li>
		</ul>
	),
	se: (
		<ul>
			<li>Ge inte ut personlig kontaktinformation i parken. Detta kan till exempel vara:</li>
			<ul>
				<li>E-mail</li>
				<li>Telefonnummer</li>
				<li>Efternamn</li>
				<li>Adress</li>
				<li>Annan privat kontaktinformation</li>
			</ul>
			<li>
				I de fall du ändå väljer att ge ut personlig information offentligt, görs detta på eget ansvar. <br />
				Kom ihåg att alla kan läsa vad du skriver i parken.
			</li>
			<li>Det är inte tillåtet att dela andra medlemmars personliga information i parken.</li>
		</ul>
	),
	en: (
		<ul>
			<li>Do not share personal information in the park. Examples of this could be:</li>
			<ul>
				<li>E-mail</li>
				<li>Phone number</li>
				<li>Surname</li>
				<li>Adress</li>
				<li>Other private applications</li>
			</ul>
			<li>
				If you still choose to share personal information publicly, it is done at your own risk. Remembe that everyone
				can read what you write in the game.
			</li>
			<li>Sharing other users' personal information in the park is prohibited.</li>
		</ul>
	),
};

const HATS_INFO = {
	dk: (
		<ul>
			<li>Det er forbudt at stjæle fra andre brugere.</li>
			<ul>
				<li>
					Hatte, som lånes ud, er på eget ansvar. Mister du en hat på grund af udlåning eller tyveri, som en moderator
					ikke er vidne til, vil din hat ikke blive returneret.
				</li>
				<li>
					Et byt er et byt. Hvis du fortryder et byt, og modparten ikke vil bytte hatten(e) tilbage, må du acceptere
					dette.
				</li>
			</ul>
		</ul>
	),
	no: (
		<ul>
			<li>Det er forbudt å stjele fra andre brukere.</li>
			<ul>
				<li>
					Hatter lånes ut på eget ansvar. Dersom du mister en hatt pga. utlån eller tyveri som ikke er sett av
					moderator, vil ikke hatten bli returnert.
				</li>
				<li>
					Hvis du angrer på et bytte du har gjort, og motparten ikke vil bytte tilbake kan vi ikke gjøre noe. Et bytte
					er et bytte.
				</li>
			</ul>
		</ul>
	),
	se: (
		<ul>
			<li>Det är förbjudet att stjäla från andra medlemmar.</li>
			<ul>
				<li>
					Hattar lånas ut under eget ansvar. I de fall du går miste om en hatt p.g.a. utlåning eller stöld som inte är
					setts av moderator, kommer hatten inte bli returnerad.
				</li>
				<li>
					Om du ångrar ett byte du har gjort, och motparten inte vill byta tillbaka, kan vi inte göra något. Ett byte är
					ett byte.
				</li>
			</ul>
		</ul>
	),
	en: (
		<ul>
			<li>It is forbidden to steal from other users.</li>
			<ul>
				<li>
					Hats are lent out at your own risk. If you lose a hat due to loans or theft not seen by a moderator, the hat
					will not be returned.{' '}
				</li>
				<li>
					If you regret a trade you made and the other party does not want to trade back, we can not do anything about
					it. A trade is a trade.
				</li>
			</ul>
		</ul>
	),
};

const CHEATING_INFO = {
	dk: (
		<ul>
			<li>Det er kun tilladt at have 1 bruger i Hundeparken. Én bruger består af max 3 hunde.</li>
			<ul>
				<li>
					Det er IKKE tilladt at bruge en anden bruger til at købe, holde eller bytte hatte. Hver bruger må have sit
					eget individuelle og sociale Hundepark-liv.
				</li>
			</ul>
			<ul>
				<li>
					I tilfælde af, at du opretter eller benytter flere brugere, vil disse blive bannet.
					<br />
				</li>
				<li>Ved gentagende lovbrud kan det få permanente konsekvenser for de involverede brugere.</li>
			</ul>
			<li>Det er forbudt at snyde sig til flere karmapoint, kødben, hatte og livspoint.</li>
			<li>
				Det er forbudt at bruge programmer, som gør, at du ikke selv behøver at udføre opgaven i spillet. Det kan for
				eksempel være programmer, hvor markøren trykker af sig selv.
				<br />
				<br />
				Brugere, som gør dette, vil blive udelukket fra Hundeparken.
			</li>
		</ul>
	),
	no: (
		<ul>
			<li>Det er kun tillat å ha 1 bruker i Hundeparken. Én bruker består av max 3 hunder.</li>{' '}
			<ul>
				<li>
					Det er IKKE tillat å bruke en annen bruker til å kjøpe, holde eller bytte hatter. Hver bruker må ha sitt eget
					individuelle og sosiale Hundepark-liv. <br />
				</li>
				<li>
					Dersom du opretter eller benytter flere brukere, vil disse bli bannet.
					<br />
				</li>
				<li>Ved gjentagende lovbrudd kan det få permanente konsekvenser for de involverte brukerne.</li>
			</ul>
			<li>Det er forbudt å jukse til seg flere karmapoeng, kjøttbein, hatter og livspoeng.</li>
			<li>
				Det er forbudt å bruke programmer som gjør at man ikke trenger å utføre oppgaver selv i spillet. For eksempel
				programmer som gjør at markøren trykker av seg selv.
				<br />
				<br />
				Brukere som gjør dette, vil bli utestengt fra Hundeparken.
			</li>
		</ul>
	),
	se: (
		<ul>
			<li>Det är endast tillåtet att ha 1 användarkonto i Hundparken. Ett användarkonto består av max 3 hundar. </li>
			<ul>
				<li>
					Det är inte tillåtet att använda en annan användare till att köpa, hålla eller byta hattar. Varje användare
					måste ha sitt eget individuella och sociala Hundeparken-liv.
				</li>
			</ul>
			<ul>
				<li>
					Om du skapar eller använder flera användare, kommer dessa bli bannade.
					<br />
				</li>
				<li>Vid upprepade regelbrott kan det få permanenta konsekvenser för involverade användare.</li>
			</ul>
			<li>Det är förbjudet att fuska sig till mer karmapoäng, köttben, hattar och livspoäng</li>
			<li>
				Det är förbjudet att använda program som gör att man inte själv behöver utföra uppgifter i spelet. Till exempel
				program som gör att markören trycker av sig själv.
				<br />
				<br />
				Medlemmar som gör detta, kommer bli avstängda från Hundparken.
			</li>
		</ul>
	),
	en: (
		<ul>
			<li>It is only allowed to have 1 user in Hundeparken. One user consists of a maximum of 3 dogs.</li>
			<ul>
				<li>
					It is NOT permitted to use another user to buy, hold or exchange hats. Each user must have their own
					individual and social Hundeparken life.
				</li>
				<li>
					If you create or use several users, these will be banned.
					<br />
				</li>
				<li>Repeated offenses can have permanent consequences for the users involved.</li>
			</ul>
			<li>It is forbidden to cheat in order to recieve more karmapoints, bones, hats and life-points.</li>
			<li>
				It is forbidden to use programs that automatically performs tasks for you in the game. For example, programs
				that makes the cursor move and click on its own.
				<br />
				<br />
				Users who break this rule, will be banned from Hundeparken.
			</li>
		</ul>
	),
};

const DISCORD_INFO = {
	dk: (
		<ul>
			<li>Brud på regler i Hundeparkens officielle Discord server kan også føre til straf i Hundeparken.</li>
		</ul>
	),
	no: (
		<ul>
			<li>Brudd på regler i Hundeparkens offisielle Discord-server kan også føre til straff i Hundeparken.</li>
		</ul>
	),
	se: (
		<ul>
			<li>Regelbrott i Hundparkens officiella Discord-server, kan medföra till medföljande straff i Hundparken.</li>
		</ul>
	),
	en: (
		<ul>
			<li>
				Violations of rules in the Hundeparken’s official Discord server, can also lead to penalties in Hundeparken.
			</li>
		</ul>
	),
};

const PROMOTION_INFO = {
	dk: (
		<ul>
			<li>Det er ikke tilladt at reklamere for andre produkter eller hjemmesider i Hundeparken</li>
			<ul>
				<li>Det gælder også multi-level marketing om eksempelvis Mary Kay eller Tupperware.</li>
				<li>Har du en hjemmeside om din pixelhund, må du dele den i forum.</li>
			</ul>
		</ul>
	),
	no: (
		<ul>
			<li>Det er ikke tillat å reklamere for andre produkter og andre hjemmesider i Hundeparken.</li>
			<ul>
				<li>
					Eksempel på produkter som ikke er lov kan være hvis du selger produkter etter forretningsmodellen multi-level
					marketing. For eksempel: Mary Kay, Herbalife, Tupperware.
				</li>
				<li>Dersom du har en hjemmeside for din hund, kan denne deles i forumet.</li>
			</ul>
		</ul>
	),
	se: (
		<ul>
			<li>Det är inte tillåtet att göra reklam för andra produkter och hemsidor i Hundparken.</li>
			<ul>
				<li>
					Exempel på produkter som inte är tillåtet kan vara om du säljer produkter efter affärsmodellen multi-level
					marketing. Till exempel: Mary Kay, Herbalife, Tupperware
				</li>
				<li>I de fall du har en hemsida för din hund, kan denna delas i forumet.</li>
			</ul>
		</ul>
	),
	en: (
		<ul>
			<li>It is not permitted to advertise other products and other websites in Hundeparken.</li>
			<ul>
				<li>
					Examples of products that are not allowed, may be if you sell products according to the business model
					multi-marketing. For example: Mary Kay, Herbalife and Tupperware.
				</li>
				<li>If your dog has it’s own website, you can choose to share it in our forums.</li>
			</ul>
		</ul>
	),
};

const BUY_SELL_INFO = {
	dk: (
		<ul>
			<li>
				Det er ikke tilladt at bytte hunde, profiler, hatte og andre ting i Hundeparken for ikke-relateret
				Hundeparken-ting. Det betyder, at du ikke må bytte med rigtige sager, legetøj, tjenester osv.
			</li>
			<li>Det er ikke tilladt at købe eller sælge hunde samt andre Hundeparken-relateret sager for rigtige penge.</li>
			<li>
				Profiler, hunde og hatte, som er til salg, eller som allerede er købt/solgt for ikke-relateret Hundeparken-ting,
				vil blive slettet. Du vil ligeledes blive udelukket fra Hundeparken, hvorefter din profil/hund/hatte, der er
				omfattet, vil blive fjernet.
			</li>
			<li>
				Det er tilladt at bytte hatte for digitale tegninger. Dette foregår på <b>EGET</b> ansvar. Vi fraråder disse byt
				grundet risikoen for at blive snydt.
			</li>
		</ul>
	),
	no: (
		<ul>
			<li>Det er ikke tillat å reklamere for andre produkter og andre hjemmesider i Hundeparken.</li>
			<ul>
				<li>
					Eksempel på produkter som ikke er lov kan være hvis du selger produkter etter forretningsmodellen multi-level
					marketing. For eksempel: Mary Kay, Herbalife, Tupperware.
				</li>
				<li>Dersom du har en hjemmeside for din hund, kan denne deles i forumet.</li>
			</ul>
		</ul>
	),
	se: (
		<ul>
			<li>
				Det är inte tillåtet att byta hundar, profiler, hattar eller andra saker i Hundparken för icke-hundparken saker.
				Det vill säga, från andra ställen på nätet, IRL-saker, leksaker, tjänster
			</li>
			<li>
				Det är inte tillåtet att köpa eller sälja hundar eller andra hundparken-relaterade saker för riktiga pengar.
			</li>
			<li>
				Profiler, hundar och hattar som ligger till salu, eller redan har blivit köpta/sålda för icke-hundparken saker
				kommer bli raderade. I de fall detta förekommer, kommer man bli avstängd från Hundparken, och profilen, hunden
				och hattarna det gäller kommer att raderas.
			</li>
			<li>
				Det är tillåtet att byta hattar mot digitala teckningar. Detta försiggår på <b>EGET</b> ansvar. Detta avråder vi
				på grund av risken att bli lurad.
			</li>
		</ul>
	),
	en: (
		<ul>
			<li>
				It is not allowed to swap dogs, profiles, hats or other Hundeparken related things to none-Hundeparken related
				things. That means things from other places on the web, IRL things, toys and services.
			</li>
			<li>It is not allowed to buy or sell dogs or other Hundeparken-related things for real money.</li>
			<li>
				Profiles, dogs and hats that are for sale, or have already been bought/sold for non-Hundeparken things will be
				deleted. If this occurs, you will be banned from Hundeparken, and the profile/dog/hat in question will be
				removed.
			</li>
			<li>
				Trading hats for digital art is allowed, but you do so at <b>YOUR OWN</b> risk. We do not recommend this type of
				trading due to part of the trade taking place outside of Hundeparken's domain, thus heightening the chance of
				being tricked.
			</li>
		</ul>
	),
};

const INTELLECTUAL_DESIGN_INFO = {
	dk: (
		<ul>
			<li>Ejerne af Hundeparken har rettighederne til spillet, grafikken og konceptet, som er Hundeparken.</li>
			<li>
				Brugerprofiler, hunde, grafik og virtuelle objekter i Parken tilhører ejerne af Hundeparken. Parken og forum er
				til rådighed for alle, som vil spille, chatte med andre og deltage i aktiviteter med andre brugere.
			</li>
			<li>Det er ikke tilladt at bruge Hundeparkens grafik eller koncept for at lave din egen version af spillet.</li>
		</ul>
	),
	no: (
		<ul>
			<li>Hundeparkens eiere eier Hundeparken, grafikken og konseptet som er Hundeparken.</li>
			<li>
				Brukerprofiler, hunder, grafikk og virtuelle objekter i parken tilhører eierne av Hundeparken. Vi stiller parken
				og forumet til disposisjon for alle som vil spille, chatte med andre, og delta i aktiviteter med brukere.
			</li>
			<li>Det er ikke tillatt å bruke Hundeparkens grafikk og konsept til å lage sin egen versjon av spillet. </li>
		</ul>
	),
	se: (
		<ul>
			<li>Hundparkens ägare äger Hundparken, grafiken och konceptet som Hundparken är.</li>
			<li>
				Medlemsprofiler, hundar, grafik och virtuella objekt i parken tillhör ägarna av Hundparken. Vi ställer parken
				och forumet till nyttjande för alla som vill spela, chatta med andra, och delta i aktiviteter med medlemmar.
			</li>
			<li>Det är inte tillåtet att använda Hundparkens grafik och koncept till att göra sin egen version av spelet.</li>
		</ul>
	),
	en: (
		<ul>
			<li>The owners of Hundeparken, own Hundeparken, the graphics and the concept that is Hundeparken.</li>
			<li>
				User profiles, dogs, graphics and virtual objects in the park belong to the owners of Hundeparken. We make the
				park and forum available to anyone who wants to play, chat with others and participate in activities with users.
			</li>
			<li>It is not allowed to use Hundeparken’s graphics and concept to create their own version of the game. </li>
		</ul>
	),
};

const USER_DESIGNS_INFO = {
	dk: (
		<ul>
			<li>
				Al grafik, som en bruger har leveret til Hundeparken, har vedkommende ophavsret på. Dog giver dette Hundeparken
				fri mulighed for brug til evig tid fremover.
			</li>
		</ul>
	),
	no: (
		<ul>
			<li>
				All grafikk som en bruker har overlevert til Hundeparken er brukerens opphavsrett, men gir Hundeparken fri bruk
				i evig tid fremover.
			</li>
		</ul>
	),
	se: (
		<ul>
			<li>
				All grafik som en medlem har överlämnat till Hundparken är medlemmens upphovsrätt, men ger Hundparken fr
				användning i evig tid framöver.
			</li>
		</ul>
	),
	en: (
		<ul>
			<li>
				All graphics handed over to Hundeparken by a user, belongs to the user by right, but gives Hundeparken free use
				forever.{' '}
			</li>
		</ul>
	),
};

const ADMIN_INFO = {
	dk: (
		<ul>
			<li>
				Det er forbudt at udgive dig for at være administrator, moderator eller udvikler i Hundeparken. <br />
				<u>
					<Link to="/team">Se her</Link>
				</u>
				, hvem der er administrator, moderator og udvikler i Hundeparken.{' '}
			</li>
			<li>
				Det er ikke tilladt at afsløre en moderator/administrator/udvikler, hvis vedkommende er usynlig. Det er heller
				ikke tilladt at afsløre under hvilken tid, vedkommende har været usynlig i rummet, når personen bliver synlig
				igen.
			</li>
		</ul>
	),
	no: (
		<ul>
			<li>
				Det er forbudt å utgi seg for å være en administrator, moderator eller utvikler i Hundeparken. <br />
				<u>
					<Link to="/team">Klikk her</Link>
				</u>{' '}
				for å se hvem som er administrator, moderator og utviklere i Hundeparken{' '}
			</li>
			<li>
				Det er ikke lov å avsløre om en moderator/administrator/dev er i rommet, dersom den er usynlig. Det er heller
				ikke lov til å avsløre hvilket tidsrom de har vært usynlig i rommet, når de kommer synlig inn i rommet igjen.
			</li>
		</ul>
	),
	se: (
		<ul>
			<li>
				Det är förbjudet att utge sig för att vara en administratör, moderator eller utvecklare för Hundparken. <br />
				<u>
					<Link to="/team">Klicka här</Link>
				</u>{' '}
				för att se vem som är administratör, moderator och utvecklare i Hundparken.{' '}
			</li>
			<li>
				Det är inte tillåtet att avslöja en moderator/administratör/utvecklare som vistas i ett rum, om denne är
				osynlig. Det är inte heller tillåtet att avslöja under vilken tid de har varit osynliga i rummet, när de blir
				synliga igen.
			</li>
		</ul>
	),
	en: (
		<ul>
			<li>
				It is forbidden to pretend to be a administrator, moderator or developer in Hundeparken. <br />
				<u>
					<Link to="/team">Click here</Link>
				</u>{' '}
				to see who the administrators, moderators and developers in Hundeparken are.{' '}
			</li>
			<li>
				It is not allowed to reveal whether a moderator/administrator/dev is in the room, if it is invisible. It is also
				not allowed to reveal what time they have been invisible in the room, when they come visibly back into the room.
			</li>
		</ul>
	),
};

const REPORTS_INFO = {
	dk: (
		<ul>
			<li>Det er forbudt at lave falske anmeldelser.</li>
			<ul>
				<li>En falsk anmeldelse er at anmelde en anden bruger for noget, vedkommende ikke har gjort.</li>
			</ul>
			<li>Det er forbudt at lyve i anmeldelser.</li>
			<li>Det er forbudt at lave anmeldelser «for sjov».</li>
		</ul>
	),
	no: (
		<ul>
			<li>Det er forbudt å lage falske anmeldelser.</li>
			<ul>
				<li>En falsk anmeldelse er å anmelde en annen bruker for noe vedkommende ikke har gjort.</li>
			</ul>
			<li>Det er forbudt å lyve i anmeldelser.</li>
			<li>Det er forbudt å lage anmeldelser «for gøy».</li>
		</ul>
	),
	se: (
		<ul>
			<li>Det är förbjudet att göra falska anmälningar.</li>
			<ul>
				<li>En falsk anmälan är att anmälan en annan användare för något vidkommande inte har gjort.</li>
			</ul>
			<li>Det är förbjudet att ljuga i anmälningar. </li>
			<li>Det är förbjudet att göra anmälningar «för skojs skull».</li>
		</ul>
	),
	en: (
		<ul>
			<li>It is forbidden to make false reports, based on false accusations. </li>
			<ul>
				<li>A false report is to report another user, for something they have not done.</li>
			</ul>
			<li>It is forbidden to lie in reports.</li>
			<li>It is forbidden to make reports «for fun».</li>
		</ul>
	),
};

const INTRODUCTION = {
	dk: (
		<p>
			Her kan du læse reglerne, som gælder, når du spiller Hundeparken. <br />
			Som bruger af Hundeparken skal du følge disse regler. <br />
			<br />I tilfælde af en lovovertrædelse kan din bruger blive udelukket fra hundeparken i en begrænset periode,
			eller permanent, afhængigt af forseelsens omfang. <br />
			<br />
			Hvis reglerne bliver brudt i stort omfang, kan Hundeparkens administratorer slette din profil og nægte dig adgang
			til Hundeparken. Ved alvorlige brud på Hundeparkens regler kan en politianmeldelse blive aktuel.
			<br />
			<br />
			En enkel huskeregel: <br />
			<b>Du skal behandle andre, som du selv vil behandles.</b>
		</p>
	),
	no: (
		<p>
			Her kan du lese reglene som gjelder når man spiller Hundeparken. <br />
			Som bruker av Hundeparken skal disse reglene følges. <br />
			<br />
			Ved lovbrudd kan brukeren din bli utestengt fra hundeparken i en begrenset periode, eller permanent, utifra
			omfanget på lovbruddet.
			<br />
			<br />
			Dersom reglene blitt brutt i stort omfang, kan Hundeparkens administratorer slette profilen og nekte deg adgang
			til Hundeparken. Dersom det blir begått alvorlige lovbrudd, kan det bli aktuelt med politianmeldelse.
			<br />
			<br />
			En enkel huskeregel: <br />
			<b>Du skal behandle andre, slik du selv ønsker å bli behandlet.</b>
		</p>
	),
	se: (
		<p>
			Här kan du läsa reglerna som gäller när man spelar Hundparken. <br />
			Som medlem i Hundparken ska dessa regler följas. <br />
			<br />
			Vid regelbrott kan ditt användarkonto bli avstängt från Hundeparken i en begränsad period, eller permanent,
			beroende på regelbrottets omfattning. <br />
			<br />I de fall då regelbrott är i större omfattning, kan Hundparkens administratörer radera profilen och neka dig
			åtkomst till Hundparken. I de fall där det handlar om allvarliga regelbrott, kan det blir aktuellt med
			polisanmälan.
			<br />
			<br />
			En enkel kom ihåg regle. <br />
			<b>Du ska behandla andra, så som du själv vill bli behandlad.</b>
		</p>
	),
	en: (
		<p>
			Here you can read the rules that apply whenever you play Hundeparken (The Dogpark). <br />
			As a user of Hundeparken, these rules apply to you, and should be followed as such. <br />
			<br />
			In case of violation of the rules you will recieve a warning, and in case of repeated violations, it will lead to
			exclusion from the park for a limited time, or permanent depending on the extent of the offence. <br />
			<br />
			If the rules have been violated on a large scale, Hundeparken’s administrators can delete the profile of the
			offender, and deny the user further access to the game. If serious offenses are committed, a police report may be
			relevant. <br />
			<br />A simple rule of thumb: <br />
			<b>You should treat others as you wish to be treated.</b>
		</p>
	),
};

export const RulesPage: FunctionComponent = () => {
	const { inlineTranslation, getTranslation } = useLanguageContext();
	const sections: InformationBoxSection[] = [
		{
			id: 'GENERAL_INFO',
			title: inlineTranslation({
				dk: 'Generel opførsel',
				no: 'GENERELL OPPFØRSEL',
				se: 'GENERELLT UPPFÖRANDE',
				en: 'GENERAL BEHAVIOUR',
			}),
			content: inlineTranslation(GENERAL_INFO),
		},
		{
			id: 'PERSONAL_INFO',
			title: inlineTranslation({
				dk: 'PERSONLIG KONTAKTINFORMATION',
				no: 'PERSONLIG KONTAKTINFORMASJON',
				se: 'PERSONLIG KONTAKTINFORMATION',
				en: 'PERSONAL INFORMATION',
			}),
			content: inlineTranslation(PERSONAL_INFO),
		},
		{
			id: 'CHEATING_INFO',
			title: inlineTranslation({
				dk: 'SNYD',
				no: 'JUKS',
				se: 'FUSK',
				en: 'CHEATING',
			}),
			content: inlineTranslation(CHEATING_INFO),
		},
		{
			id: 'HATS_INFO',
			title: inlineTranslation({
				dk: 'TYVERI, HATTE OG BYT',
				no: 'TYVERI, HATTER OG BYTTING',
				se: 'STÖLD, HATTAR OCH BYTEN',
				en: 'THEFT, HATS & TRADING',
			}),
			content: inlineTranslation(HATS_INFO),
		},
		{
			id: 'DISCORD_INFO',
			title: inlineTranslation({
				dk: 'HUNDEPARKENS DISCORD SERVER',
				no: 'HUNDEPARKENS DISCORD-SERVER',
				se: 'HUNDPARKENS DISCORD SERVER',
				en: "HUNDEPARKEN'S DISCORD SERVER",
			}),
			content: inlineTranslation(DISCORD_INFO),
		},
		{
			id: 'PROMOTION_INFO',
			title: inlineTranslation({
				dk: 'REKLAMERING',
				no: 'REKLAMERING',
				se: 'REKLAM',
				en: 'ADVERTISING',
			}),
			content: inlineTranslation(PROMOTION_INFO),
		},
		{
			id: 'BUY_SELL_INFO',
			title: inlineTranslation({
				dk: 'KØB & SALG',
				no: 'KJØP & SALG',
				se: 'KÖP OCH SÄLJ',
				en: 'PURCHASE & SALE',
			}),
			content: inlineTranslation(BUY_SELL_INFO),
		},
		{
			id: 'INTELLECTUAL_DESIGN_INFO',
			title: inlineTranslation({
				dk: 'HUNDEPARKENS INTELLEKTUELLE RETTIGHEDER',
				no: 'HUNDEPARKENS INTELLEKTUELLE EIENDOM',
				se: 'HUNDPARKENS INTELLEKTUELLA EGENDOM',
				en: "HUNDEPARKEN'S INTELLECTUAL PROPERTY",
			}),
			content: inlineTranslation(INTELLECTUAL_DESIGN_INFO),
		},
		{
			id: 'USER_DESIGNS_INFO',
			title: inlineTranslation({
				dk: 'BRUGERDESIGN',
				no: 'BRUKERDESIGN',
				se: 'ANVÄNDARDESIGN',
				en: 'USERDESIGN',
			}),
			content: inlineTranslation(USER_DESIGNS_INFO),
		},
		{
			id: 'ADMIN_INFO',
			title: inlineTranslation({
				dk: 'ADMINISTRATOR, MODERATOR OG UDVIKLER',
				no: 'ADMINISTRATORER, MODERATORER OG UTVIKLER',
				se: 'ADMINISTRATÖRER, MODERATORER OCH UTVECKLARE',
				en: 'ADMINISTRATORS, MODERATORS AND DEVELOPERS',
			}),
			content: inlineTranslation(ADMIN_INFO),
		},
		{
			id: 'REPORTS_INFO',
			title: inlineTranslation({
				dk: 'ANMELDELSER',
				no: 'ANMELDELSER',
				se: 'ANMÄLNINGAR',
				en: 'REPORTS',
			}),
			content: inlineTranslation(REPORTS_INFO),
		},
	];
	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					title={inlineTranslation({
						dk: 'Guide til god opførsen i hundeparken',
						no: 'Guide til god oppførsel i hundeparken',
						se: 'Guide til gott uppförande i hundeparken',
						en: 'A guide to good behaviour in hundeparken',
					})}
					color="#ffc600"
					icon="/images/paragraf.png"
					sections={[
						{
							content: (
								<p>
									{inlineTranslation(INTRODUCTION)}
									<br />
									<b>{getTranslation('TEAM_MENU_TITLE')}</b>
									<div className={styles.menu}>
										{sections.map(section => {
											return (
												<Link to={`#${section.id}`}>
													{'>'} {typeof section.title === 'string' ? capitalize(section.title) : section.title}
												</Link>
											);
										})}
									</div>
								</p>
							),
						},
						...sections,
					]}
				/>
			</div>
		</div>
	);
};
