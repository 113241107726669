export function capitalize(input: string) {
	if (input.length === 0) {
		return '';
	}

	let output = input.substring(0, 1).toUpperCase();

	return output + input.substring(1).toLocaleLowerCase();
}

export function loadScript(src: string, type: string = '') {
	return new Promise(resolve => {
		const script = document.createElement('script');
		script.onload = resolve;
		script.src = src;
		script.async = true;
		script.defer = true;
		script.type = type;

		document.body.appendChild(script);
	});
}

type Season = 'normal' | 'winter' | 'autumn';

export function useSeason() {
	let season: Season = 'normal';
	const date = new Date();

	if (date.getMonth() === 11 || date.getMonth() === 0) {
		season = 'winter';
	} else if (date.getMonth() >= 9) {
		season = 'autumn';
	}

	return { season, inlineSeason: (data: Record<Season, string>) => data[season] };
}

export function validateInput(input: HTMLInputElement, spaceCheck = false) {
	input.value = input.value.substring(0, 14);
	input.value = input.value.replace(/[^a-øA-Ø 0-9*]/g, '');

	if (spaceCheck) {
		while (input.value.length > 0 && input.value[0] === ' ') {
			input.value = input.value.substring(1, input.value.length);
		}

		while (input.value.length > 0 && input.value[input.value.length - 1] === ' ') {
			input.value = input.value.substring(0, input.value.length - 1);
		}
	}
}
