import { FunctionComponent } from 'react';
import { Link, Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { Header } from './Header/Header';
import { Footer } from './Footer';
import style from './Layout.module.scss';
import classNames from 'classnames';

export const Layout: FunctionComponent = () => {
	const { pathname } = useLocation();
	return (
		<>
			<Header />
			<div className={style.container}>
				<Outlet />
				{pathname !== '/' ? (
					<div className={style.navigatorOuterContainer}>
						<div className={style.navigatorContainer}>
							<Link to="/">
								<button className={style.arrow} />
							</Link>
							<Link to={pathname}>
								<button className={classNames(style.arrow, style.top)} />
							</Link>
						</div>
					</div>
				) : undefined}
			</div>
			<Footer />
			<ScrollRestoration />
		</>
	);
};
