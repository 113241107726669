import { InformationBox, TranslationKey, Platform, useLanguageContext } from '../components';
import { Fragment, FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';
import { useGetTeamQuery } from './Teams.graphql';
import { Link } from 'react-router-dom';

const sections: { title: TranslationKey; description: TranslationKey; icon: string }[] = [
	{
		title: 'TEAM_ADMIN_TITLE',
		description: 'TEAM_ADMIN',
		icon: 'admin',
	},
	{
		title: 'TEAM_MODERATOR_TITLE',
		description: 'TEAM_MODERATOR',
		icon: 'Moderator',
	},
	{
		title: 'TEAM_DEV_TITLE',
		description: 'TEAM_DEV',
		icon: 'dev',
	},
];

const TeamSection: FunctionComponent<{ section: (typeof sections)[number] }> = ({ section }) => {
	const { data } = useGetTeamQuery({ variables: { types: [section.icon] } });
	const { getTranslation } = useLanguageContext();

	return (
		<Fragment>
			<InformationBox
				id={section.title}
				title={getTranslation(section.title) as string}
				subBox
				color="#ffcaee"
				sections={[{ content: getTranslation(section.description as any) }]}
				// sections={[{ content: <p dangerouslySetInnerHTML={{ __html: getTranslation(section.description as any) }} /> }]}
			/>
			<div className={styles.teamContainer}>
				{(data?.team ?? []).map(dog => (
					<Platform
						color={dog.color}
						shadowColor={dog.shadowColor}
						dogName={dog.name}
						wear={dog.wear}
						hasPodium
						key={dog.name}
					/>
				))}
			</div>
		</Fragment>
	);
};

export const TeamPage: FunctionComponent = () => {
	const { getTranslation } = useLanguageContext();
	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					title={'Holdet bag hundeparken'}
					color="#ff00ac"
					sections={[
						{
							content: (
								<p>
									<b>{getTranslation('TEAM_MENU_TITLE')}</b>
									<div className={styles.menu}>
										{sections.map(section => {
											return (
												<Link to={`#${section.title}`}>
													{'>'} {getTranslation(section.title)}
												</Link>
											);
										})}
									</div>
								</p>
							),
						},
					]}
				/>

				{/* Get all platforms from API based on bagde */}
				{sections.map(section => {
					return <TeamSection key={section.title} section={section} />;
				})}
			</div>
		</div>
	);
};
