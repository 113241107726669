import { createContext, useContext, useState, FunctionComponent } from 'react';
import { Lang } from './utils';
import { translations } from './Translations';
import { useSetLanguageMutation, Lang as GQLLang } from './Language.graphql';

const languageMap: { [k: string]: Lang } = {
	da: Lang.Dk,
	'da-dk': Lang.Dk,
	'en-us': Lang.En,

	'nn-no': Lang.No,
	nb: Lang.No,
	'nb-no': Lang.No,
	'se-no': Lang.No,

	'se-se': Lang.Se,
	sv: Lang.Se,
	'sv-fi': Lang.Se,
	'sv-se': Lang.Se,
};

function guessLanguage() {
	const lastLanguage = localStorage.getItem('language');

	if (lastLanguage && Object.values(Lang).includes(lastLanguage as Lang)) {
		return lastLanguage as Lang;
	}

	const lang = languageMap[navigator.language.toLowerCase()];

	return lang ?? Lang.En;
}

export type TranslationKey = keyof typeof translations;
export interface LanguageContext {
	language: Lang;
	setLanguage: (lang: Lang) => void;
	inlineTranslation: <RetType extends string | string[] | JSX.Element>(translation: Record<Lang, RetType>) => RetType;
	getTranslation: <Key extends TranslationKey>(key: Key) => (typeof translations)[Key][Lang];
}

const Context = createContext<LanguageContext>({
	getTranslation: key => key,
} as LanguageContext);

export const useLanguageContext = () => useContext<LanguageContext>(Context);

export const LanguageProvider: FunctionComponent<{ children: JSX.Element }> = props => {
	const [language, setLanguage] = useState<GQLLang>(guessLanguage());
	const [setUserLanguage] = useSetLanguageMutation();

	return (
		<Context.Provider
			value={{
				language,
				inlineTranslation: <RetType extends string | string[] | JSX.Element>(
					translation: Record<Lang, RetType>
				): RetType => {
					return translation[language];
				},
				setLanguage: lang => {
					localStorage.setItem('language', lang);
					setLanguage(lang);
					window.scrollTo(0, 0);
					setUserLanguage({
						variables: {
							language: lang,
						},
					}).catch();
				},
				getTranslation: <Key extends TranslationKey>(key: Key) => {
					const translation = translations[key];
					if (!translation) {
						console.log('MISSING translation', `"${key}"`, language);
					}

					return translation[language] as (typeof translations)[Key][typeof language]; // ?? key;
				},
			}}>
			<div key={language}>{props.children}</div>
		</Context.Provider>
	);
};
