import { Fragment, FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';
import helpStyles from './HelpPage.module.scss';
import { InformationBox, InformationBoxSection, useLanguageContext } from '../components';
import { Link } from 'react-router-dom';

type HelpSection = InformationBoxSection & {
	image?: string;
	subTitle: string;
	title: string;
};

export const HelpPage: FunctionComponent = () => {
	const { inlineTranslation, getTranslation } = useLanguageContext();

	const sections: HelpSection[] = [
		{
			title: inlineTranslation({
				dk: 'Chat',
				no: 'Chat',
				se: 'Chatt',
				en: 'Chat',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvordan chatter jeg?',
				no: 'Hvordan chatter jeg?',
				se: 'Hur chattar jag?',
				en: 'How do i chat?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Tryk på mellemrumstasten/space for at åbne chatboblen.
						<br /> Skriv og tryk derefter på <b>ENTER</b> for at sende din besked.
						<br />
						<br /> For at åbne chatlog og se, hvad andre brugere skriver, skal du trykke på{' '}
						<img src="images/help/chatlog_dk.png" alt="chat knap" /> nederst i venstre hjørne.
					</Fragment>
				),
				se: (
					<Fragment>
						Tryck på mellanslag/space för att öppna chattbubblan.
						<br /> Skriv, och tryck därefter på <b>ENTER</b> för att skicka det du har skrivit.
						<br />
						<br /> För att se vad andra har skrivit, tryck på <img src="images/help/chatlog_se.png" alt="" /> längst
						nere i vänstra hörnet för att öppna textloggen.
					</Fragment>
				),

				no: (
					<Fragment>
						Trykk på mellomromstasten/space for å åpne chatboblen.
						<br />
						Skriv og trykk deretter på <b>ENTER</b> for å sende det du har skrevet.
						<br />
						<br />
						For å se hva alle har skrevet, trykk på <img src="images/help/chatlog_no.png" alt="chat knap" /> nederst i
						venstre hjørnet for å åpne chatloggen.
					</Fragment>
				),
				en: (
					<Fragment>
						Press the spacebar button on your keyboard to open the chat-bubble.
						<br /> Write, then push <b>ENTER</b> on your keyboard to send what you wrote.
						<br />
						<br />
						To see what everyone else has written, press the <img src="images/help/chatlog_en.png" alt="" /> in the
						bottom left corner to open the chatlog.
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/chat_no.gif',
				no: '/images/help/chat_no.gif',
				se: '/images/help/chat_se.gif',
				en: '/images/help/chat_en.gif',
			}),
		},
		{
			title: inlineTranslation({
				dk: 'Kødben og gravning',
				no: 'Kjøttbein & gravning',
				se: 'Grävning och köttben',
				en: 'Bones & digging',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvad er det, og hvordan får jeg det?',
				no: 'Hva er det, og hvordan får jeg det?',
				se: 'Vad är det, och hur får jag det?',
				en: 'What is it, and how do I get it?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Kødben er Parkens valuta. <br />
						For at få fat i disse kødben, skal du grave i jorden eller sandet. Tryk på din hund med højre musetast og
						vælg «Grav». Af og til vil der dukke et kødben og tal op over hovedet på din hund. Det betyder, at du har
						fundet kødben, som lander automatisk i din rygsæk.
					</Fragment>
				),
				no: (
					<Fragment>
						Kjøttbein er parkens valuta. <br />
						For å få kjøttbein må du grave i jorden, eller sanden. Når du trykker på hunden din med høyre musetast, og
						velger «Grav» vil det noen ganger dukke opp et kjøttbein over hodet på hunden. Dette indikerer at du har
						funnet et kjøttbein.
					</Fragment>
				),
				se: (
					<Fragment>
						Köttben är parkens valuta. <br />
						För att få köttben behöver du gräva i jord eller i sand. När du högerklickar på din hund och väljer «Gräv»
						kommer det ibland visas ett köttben över huvudet på din hund, och detta indikerar att du har hittat ett
						köttben.
					</Fragment>
				),

				en: (
					<Fragment>
						Bones is the currency of the park. <br />
						To get bones you need to dig in the dirt or sand. Click «Dig» by right clicking your dog and a bone will
						sometimes appear above the head of your dog, which indicates that you’ve found a bone.
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/dig_dk.gif',
				no: '/images/help/dig_no.gif',
				se: '/images/help/dig_se.gif',
				en: '/images/help/dig_en.gif',
			}),
		},
		{
			title: 'Karma',
			subTitle: inlineTranslation({
				dk: 'Hvad bliver det brugt til, og hvordan får jeg det?',
				no: 'Hva brukes det til og hvordan får jeg det?',
				se: 'Vad används det till och hur får jag det?',
				en: 'What is it used for, and how do I get it?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Du samler på karma for at stige i rang via Visdomsbjerget. For at få karma kan du gøre flere aktiviteter som
						at snuse til dig selv eller andre. Som du stiger i rang, vil din hund kunne flere funktioner, hvilket du
						også kan bruge til at samle på endnu mere karma. <br />
						<br />
						Du kan maksimum få 50 karmapoint pr. dag.
					</Fragment>
				),
				no: (
					<Fragment>
						Karma samler man for å kunne gå på Visdomsberget, slik at man kan stige i rang. For å få karma kan du blant
						annet snuse deg selv eller andre. Etterhvert som du stiger i rang, vil din hund lære andre evner som du også
						kan bruke for å samle karma. <br />
						<br />
						Man får samlet maks 50 karmapoeng pr dag.
					</Fragment>
				),
				se: (
					<Fragment>
						Karma samlar man för att kunna gå till Visdomsberget och stiga i rang. För att få karma kan du till exempel
						sniffa på dig själv eller på andra. Så småningom när du stiger i rang kommer din hund att lära sig nya
						förmågor som du också kan använda för att samla karma.
						<br />
						<br />
						Man kan samla max 50 karmapoäng per dag.
					</Fragment>
				),

				en: (
					<Fragment>
						Karma is points you collect to be able to enter Visdomsberget, in order to rise in rank. To get karma, you
						can sniff yourself or others. Eventually, as you rise in rank, your dog will learn new abilites you can use
						to collect karma easier. <br />
						<br /> You can only collect a maximum of 50 karmapoints per day.
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/karma_dk.gif',
				no: '/images/help/karma_no.gif',
				se: '/images/help/karma_se.gif',
				en: '/images/help/karma_en.gif',
			}),
		},
		{
			title: inlineTranslation({
				dk: 'Visdomsbjerget',
				no: 'Visdomsberget',
				se: 'Visdomsberget',
				en: 'Visdomsberget',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvad er det og hvorfor?',
				no: 'Hva er det og hvorfor?',
				se: 'Vad är det och varför går man dit?',
				en: 'What is it, and what’s its purpose?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Helt nord for Parken, nærmere bestemt Nordpoten, finder du Visdomsbjerget. Her kan du svare rigtigt på
						tilfældige spørgsmål, hvorefter din hund stiger i rang. Svarer du forkert, vil du kun miste dine karmapoint,
						og ingen andre effekter opstår. <br />
						<br />
						Du skal have minimum 15 karmapoint for at besvare et spørgsmål i Visdomsbjerget.
					</Fragment>
				),
				no: (
					<Fragment>
						Visdomsberget finner du helt nord i parken, i rommet som heter Nordpoten. På visdomsberget må du svare
						riktig på spørsmålene for at hunden din skal stige i rang. Svarer du feil, vil du kun miste karmapoeng, og
						det vil ikke ha noen annen effekt. <br />
						<br />
						Du må ha minimum 15 karmapoeng for å kunne komme inn.
					</Fragment>
				),
				se: (
					<Fragment>
						Visdomsberget hittar du längst i norr i parken, i rummet som heter Nordpoten. . I Visdomsberget behöver du
						svara rätt på frågor för att din hund ska stiga i rang. Svarar du fel kommer du att förlora karmapoäng, men
						utöver det kommer det inte ske någonting annat. <br />
						<br />
						Du behöver ha minst 15 karmapoäng för att komma in.
					</Fragment>
				),

				en: (
					<Fragment>
						Visdomsberget, roughly translated to The mountain of Wisdom, can be found north in the park, in the room
						called Nordpoten. In Visdomsberget, you’ll have to answer the given questions correctly in order to rise in
						rank. If you pick the wrong answer, you’ll lose the karma-points you paid to enter, without any other side
						effects. <br />
						<br /> You need a minimum of 15 karmapoints in order to enter.
					</Fragment>
				),
			}),
			image: '/images/help/vb.gif',
		},
		{
			title: inlineTranslation({
				dk: 'Rang',
				no: 'Rang',
				se: 'Rang',
				en: 'Rank',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvad er det?',
				no: 'Hva er det?',
				se: 'Vad är det?',
				en: 'What’s that?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Ved at besvare spørgsmål i Visdomsbjerget vil din hund med tiden opnå en ny rang. Hver rang har sine
						egenskaber, som du får med dig hele hundelivet. Jo højere rang, du får, jo flere spørgsmål skal du igennem.
						Du kan følge med på din karmabar <img src="images/help/bar.png" alt="" /> for at se, hvor lang vej du har
						igen.
					</Fragment>
				),
				no: (
					<Fragment>
						Ved å gå på Visdomsberget, vil hunden din etterhvert oppnå en ny rang. Hver rang har hver sine egenskaper,
						som man får med seg hele hundelivet. Det blir fler og fler spørsmål å svare på, jo høyere rang du kommer opp
						i. Du kan følge med på karmabaren din <img src="images/help/bar.png" alt="" /> for å se hvor lang vei du har
						igjen.
					</Fragment>
				),
				se: (
					<Fragment>
						Genom att gå till Visdomsberget kommer din hund så småningom att uppnå en ny rang. Varje ny rang har sina
						egenskaper som man får med sig genom hela hundlivet. Det blir fler och fler frågor att svara på ju högre
						rang man kommer upp i. Du kan titta på din <img src="images/help/bar.png" alt="" /> för att se hur långt du
						har kvar till nästa rang.
					</Fragment>
				),

				en: (
					<Fragment>
						Through entering Visdomsberget, your dog will eventually reach a new rank. Each rank has its own abilities,
						which you will be able to use throughout your dog's life. With each rank, the number of questions you need
						to answer will increase. You can keep an eye on your karma-bar <img src="images/help/bar.png" alt="" /> to
						see how long you have left before reaching the next rank.
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/rang_dk.gif',
				no: '/images/help/rang_no.gif',
				se: '/images/help/rang_se.gif',
				en: '/images/help/rang_en.gif',
			}),
		},
		{
			title: inlineTranslation({
				dk: 'Kobbel',
				no: 'Kobbel',
				se: 'Kobbel',
				en: 'Pack',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvad er det?',
				no: 'Hva er det?',
				se: 'Vad är det?',
				en: 'What’s that?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Når du opretter en ny hund, bliver den født i et tilfældigt kobbel. I Hundeparken har vi seks forskellige
						kobler, og hver af dem har deres egen hule med en unik shop. Du har kun adgang til det kobbel, du er medlem
						af.
					</Fragment>
				),
				no: (
					<Fragment>
						Når du lager ny hund blir den født i en tilfeldig kobbel. Det finnes 6 forskjellige kobbel, og hver av dem
						har sin egen hule, med en unik butikk. Man har kun tilgang til den kobbelen man er medlem av.{' '}
					</Fragment>
				),
				se: (
					<Fragment>
						När du skapar en ny hund blir den slumpmässigt tilldelad ett koppel. Det finns 6 stycken olika koppel, som
						alla har ett varsitt eget rum med en unik shopp. Man har endast tillträde till det kopplet man är medlem i.
					</Fragment>
				),
				en: (
					<Fragment>
						When you make a new dog, it will be born into a random pack. There are 6 different packs, and each of them
						has its own cave, with a unique shop. You only have access to your pack’s cave.
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/kobbel_dk.gif',
				no: '/images/help/kobbel_no.gif',
				se: '/images/help/kobbel_se.gif',
				en: '/images/help/kobbel_en.gif',
			}),
		},
		{
			title: 'Koma',
			subTitle: inlineTranslation({
				dk: 'Hvad er det, og hvordan gør jeg?',
				no: 'Hva er det og hvordan?',
				se: 'Vad är det och hur gör man?',
				en: 'What is that, and how?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Når du lægger din hund i koma, betyder det, at du ikke er ved skærmen. For at lægge din hund i koma, så tryk
						på <img src="images/help/koma.png" alt="" /> nederst i venstre hjørne eller på ned-piltasten på dit
						tastatur.
					</Fragment>
				),
				no: (
					<Fragment>
						Når du legger hunden din i koma, betyr det at du er borte fra skjermen. <br />
						<br />
						For å legge hunden din i koma, trykk på <img src="images/help/koma.png" alt="" /> nederst i venstre hjørne,
						eller piltast ned på tastaturet.
					</Fragment>
				),
				se: (
					<Fragment>
						När du lägger din hund i koma visar det att du är borta från datorn. <br />
						<br />
						För att lägga din hund i koma trycker du på <img src="images/help/koma.png" alt="" /> längst nere i vänstra
						hörnet, eller så trycker du på neråt-pilen på tangentbordet.
					</Fragment>
				),
				en: (
					<Fragment>
						When you put your dog in a coma, it means that you’ve temporary left the computer.
						<br />
						<br /> In order to put your dog in a coma, push <img src="images/help/coma_en.png" alt="" /> in the bottom
						corner, or the arrow key down on the keyboard.
					</Fragment>
				),
			}),
			image: '/images/help/koma.gif',
		},
		{
			title: inlineTranslation({
				dk: 'Snuse',
				no: 'Snuse',
				se: 'Sniffa',
				en: 'Sniffing',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvordan snuser jeg til en anden hund?',
				no: 'Hvordan snuse på en annen hund?',
				se: 'Hur sniffar jag på en annan hund?',
				en: 'How to sniff another dog?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Med højre musetast trykker du på den hund, du gerne vil snuse til. Bagefter trykker du på «Snus». Du kan
						også holde venstre musetast inde for at åbne samme menu. Her vil den anden hunds snuseprofil også åbne.
					</Fragment>
				),
				no: (
					<Fragment>
						Trykk med høyre musetast på hunden du vil snuse. Du kan også holde inne venstre museknapp for å få opp samme
						meny. Da vil også hundens snuseprofil åpne seg.
					</Fragment>
				),
				se: (
					<Fragment>
						Högerklicka på hunden du vill sniffa på. Du kan också hålla inne vänster musknapp när du klickar på en hund,
						för att få upp samma meny. Genom att göra detta kommer hundens profilsida att öppna sig.
					</Fragment>
				),
				en: (
					<Fragment>
						In order to bring up the sniffing profile button, right click the dog you want to sniff. You can also hold
						down the left mouse button to bring up the same menu. Click «Sniff» and the sniffing profile will open.
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/snifother_dk.gif',
				no: '/images/help/snifother_no.gif',
				se: '/images/help/snifother_se.gif',
				en: '/images/help/snifother_en.gif',
			}),
		},
		{
			title: inlineTranslation({
				dk: 'Venner',
				no: 'Venner',
				se: 'Vänner',
				en: 'Friends',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvordan bliver jeg venner med en anden hund?',
				no: 'Hvordan blir jeg venn med en annen hund?',
				se: 'Hur sniffar jag på en annan hund?',
				en: 'How do I befriend another dog?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						For at blive ven med en anden hund skal du højreklikke på den hund, du gerne vil blive venner med. Du kan
						også holde venstre musetast inde. Ved begge vælger du dernæst «Ven». <br />
						Hvis en anden hund spørger dig om at blive venner, vil en tekstbesked komme over din hund. Her skal du enten
						acceptere eller afvise forespørgslen.
						<br />
						Når du er blevet venner med en hund, kan du finde vedkommende på din liste over venner i din snuseprofil.
						<br />
						<i>Du skal have opnået rangen Slædehund for at kunne sende en venneanmodning til en anden hund.</i>
					</Fragment>
				),
				no: (
					<Fragment>
						For å bli venn med en annen hund må du høyreklikke på hunden, eller holde inne venstre musetast, og velg
						«Venn». <br />
						Hvis en annen hund spør deg om å bli venn, vil det komme opp en boble over hunden din, hvor du skal
						akseptere eller avbryte venneforespørselen.
						<br />
						Når du har blitt venn med en hund, vil den ligge på vennelisten i snuseprofilen din.
						<br />
						<br />
						<i>Man må ha oppnådd rangen Sledehund for å kunne sende venneforespørsel til en annen hund.</i>
					</Fragment>
				),
				se: (
					<Fragment>
						För att bli vän med en annan hund behöver du högerklicka på hunden, eller hålla inne vänsterklick, och välja
						«Vän». <br />
						Ifall en annan hund frågar dig om att bli vän så kommer det komma upp en bubbla över din hund, där du kan
						acceptera eller avbryta vänförfrågan.
						<br /> När du har blivit vän med en hund kommer den att hamna i vänlistan inne på din profilsida.
						<br />
						<br />
						<i>Du måste ha uppnått rangen Slädhund för att kunna skicka en vänförfrågan till en annan hund.</i>{' '}
					</Fragment>
				),
				en: (
					<Fragment>
						To become friends with another dog, right-click on the dog, or hold down the left mouse button and select
						«friend». <br />
						If another dog sends you a friend request, a bubble will appear above your dog, where’ll you’ll have to
						accept or deny the request. <br /> Once you’ve befriended another dog, the dog will appear in your friend
						list on your sniffing profile.
						<br />
						<br />
						<i>You must have achieved the rank Sledehund to be able to send a friend request to another dog.</i>
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/friend_dk.gif',
				no: '/images/help/friend_no.gif',
				se: '/images/help/friend_se.gif',
				en: '/images/help/friend_en.gif',
			}),
		},
		{
			title: inlineTranslation({
				dk: 'Snuseprofil',
				no: 'Snuseprofil',
				se: 'Profilsifa',
				en: 'Sniffing profile',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvad er det?',
				no: 'Hva er det?',
				se: 'Vad är det?',
				en: 'What’s that?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Snuseprofilen er profilen til din hund, som du finder ved at snuse til dig selv. Du kan også finde andre
						hundes snuseprofiler ved at snuse til dem. I snuseprofilen finder du en oversigt over venner, hatte i
						rygsækken og livspoint.
					</Fragment>
				),
				no: (
					<Fragment>
						Snuseprofilen er din hunds profil, og du finner den ved å snuse på deg selv. Du kan også finne andre hunders
						snuseprofil ved å snuse på dem. Der kan du finne oversikt over venner, hattene i ryggsekken og din hunds
						livspoengbar.
					</Fragment>
				),
				se: (
					<Fragment>
						Profilsidan är din hunds information, som du hittar genom att sniffa på dig själv. Där kan du hitta en
						översikt över vänner, hattar i ryggsäcken och din hunds livspoängsmätare. Du kan också hitta andra hundars
						profilsidor genom att sniffa på dem.
					</Fragment>
				),
				en: (
					<Fragment>
						When you make a new dog, it will be born into a random pack. There are 6 different packs, and each of them
						has its own cave, with a unique shop. You only have access to your pack’s cave.
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/snif_dk.gif',
				no: '/images/help/snif_no.gif',
				se: '/images/help/snif_se.gif',
				en: '/images/help/snif_en.gif',
			}),
		},
		{
			title: inlineTranslation({
				dk: 'Livspoint',
				no: 'Livspoeng',
				se: 'Livspoäng',
				en: 'Lifepoints',
			}),
			subTitle: inlineTranslation({
				dk: 'Kan min hund dø?',
				no: 'Kan hunden min dø?',
				se: 'Kan min hund dö?',
				en: 'Can my dog die?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Din hund kan dø, hvis du ikke passer på den. <br />
						Dagen, hvor din hund bliver oprettet, får du 60 livspoint. Din hund mister 1 livspoint hver dag, men
						modtager 7 nye livspoint hver dag du logger ind i parken. <br />
						Bliver din hund belønnet af en anden hund igennem din hunds snuseprofil, får du tildelt 1 livspoint. De
						point, som er tildelt gennem belønning i snuseprofilen, kan overstige 60 livspoint frem til dagen du logger
						ind.
					</Fragment>
				),
				no: (
					<Fragment>
						Ja, hunden din dør om du ikke tar vare på den.
						<br /> Den dagen hunden din blir laget, får du 60 livspoeng. Man mister 1 livspoeng hver dag, men mottar 7
						livspoeng for hver dag man logger inn i parken. <br />
						Dersom hunden din blir belønnet av en annen hund i snuseprofilen din, blir du tildelt 1 livspoeng. Poeng man
						får når man blir belønnet, kan overstige 60 poeng, frem til dagen du logger inn igjen.
					</Fragment>
				),
				se: (
					<Fragment>
						Ja, din hund dör om du inte tar hand om den. Dagen din hund skapas får den 60 livspoäng. Man förlorar 1
						livspoäng varje dag, men får 7 livspoäng per inloggning i parken. <br />
						Ifall hunden blir belönad inne på profilsidan blir den tilldelad 1 livspoäng, och detta kan överstiga 60
						poäng fram tills dagen du loggar in.{' '}
					</Fragment>
				),
				en: (
					<Fragment>
						Yes, your dog will die if you don’t take care of it. The day your dog is born, you recieve 60 lifepoints.
						You lose 1 lifepoint every day, but recieve 7 life-point per daily login to the park. <br />
						If your dog is rewarded in the sniffing profile by another user, you will be awarded 1 life-point, and this
						can exceed 60 points while you are inactive, but it will reset the day you log in if you have exceeded 60
						life-points.
					</Fragment>
				),
			}),
			image: 'images/help/lifepoints.png',
		},
		{
			title: inlineTranslation({
				dk: 'Rygsæk',
				no: 'Ryggsekk',
				se: 'Ryggsäck',
				en: 'Backpack',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvad er det?',
				no: 'Hva er det?',
				se: 'Vad är det?',
				en: 'What’s that?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Din rygsæk opbevarer de hatte, som din hund ejer. Alle hunde starter med fem pladser, og du får en ny plads,
						hver gang din hund bliver ældre, indtil du til sidst har 18 pladser.
					</Fragment>
				),
				no: (
					<Fragment>
						Ryggsekken er der hattene dine blir lagret. Alle hunder starter med 5 plasser, og du får en ny plass hver
						gang hunden din fyller år, frem til det er 18 plasser.
					</Fragment>
				),
				se: (
					<Fragment>
						Ryggsäcken är platsen där dina hattar förvaras. Alla hundar startar med 5 platser, och du får sedan en ny
						plats varje gång din hund fyller år, tills dess att den har totalt 18 platser.
					</Fragment>
				),
				en: (
					<Fragment>
						The backpack is where you store your hats. All dogs start with 5 storage spaces, and you get a new space
						each time your dog ages a year until there’s a total of 18 spaces.
					</Fragment>
				),
			}),
			image: 'images/help/backpack.gif',
		},
		{
			title: inlineTranslation({
				dk: 'Tag hat af/på',
				no: 'Tag på/av hatt',
				se: 'Ta på/av hatt',
				en: 'Put on/take of hat',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvordan gør jeg?',
				no: 'Hvordan gjør jeg det?',
				se: 'Hur gör jag det?',
				en: 'How do I do it?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						For at tage en hat på, skal du først trykke på hatten i din rygsæk i højre hjørne. Bagefter kommer din hat
						frem i en stor lyserød firkant, som du ligeledes trykker på. Dernæst havner hatten på din hunds ryg. <br />
						Til sidst trykker du på din hund med højre musetast og vælger «Tag på» i menuen. <br />
						For at tage din hat af, som sidder på hovedet, trykker du «Tag af».
						<br />
						For at tage en hat af, som sidder i munden, trykker du «Tag ud».
					</Fragment>
				),
				no: (
					<Fragment>
						For å ta på en hatt, må du først trykke på hatten i ryggsekken i høyre hjørnet. Når det er gjort, trykker du
						i den store rosa firkanten – da vil hatten havne på ryggen din.
						<br />
						Når hatten er på ryggen din, høyreklikker du på musetasten og velger «Ta på» fra menyen.
						<br />
						For å ta av en hatt som er på hodet velger du «Ta av».
						<br />
						For å ta av en hatt som er i munnen velger du «Ta ut».
					</Fragment>
				),
				se: (
					<Fragment>
						För att ta på en hatt behöver du först trycka på hatten i ryggsäcken nere i högra hörnet. När det är gjort
						trycker du i den stora rosa fyrkanten - då kommer hatten hamna på din rygg.
						<br /> När hatten är på ryggen så högerklickar du på dig själv och väljer «Ta på» i menyn. <br />
						För att ta av en hatt som är på huvudet väljer du «Ta av». <br />
						För att ta av en hatt som är i munnen väljer du «Ta ut».
					</Fragment>
				),
				en: (
					<Fragment>
						In order to dress your dog with a hat, you’ll first have to click on the hat in your backpack at the bottom
						right corner. Once that’s done, you click on the big pink square - then the hat will appear on your back.
						<br /> Once the hat is on your back, you right click on your dog, and pick «Put on» from the menu. <br />
						In order to remove a hat placed on the head, pick «Take off». <br />
						In order to remove a hat placed in the mouth, pick «Take out».
					</Fragment>
				),
			}),
			image: inlineTranslation({
				dk: '/images/help/hat_dk.gif',
				no: '/images/help/hat_no.gif',
				se: '/images/help/hat_se.gif',
				en: '/images/help/hat_en.gif',
			}),
		},
		{
			title: inlineTranslation({
				dk: 'Lyd og effekter',
				no: 'Lyd og effekter',
				se: 'Ljud och effekter',
				en: 'Sounds & effects',
			}),
			subTitle: inlineTranslation({
				dk: 'Hvordan justere jeg på lyden, og hvor finder jeg det?',
				no: 'Hvordan skrur jeg av lyden, og hvor finner jeg det?',
				se: 'ur stänger jag av ljudet och hur hittar jag det?',
				en: 'How do I turn off sounds, and where do I find it?',
			}),
			content: inlineTranslation({
				dk: (
					<Fragment>
						Nederst til venstre i Parken er der en del forskellige knapper:
						<br />
						<br />
						<img src="images/help/buttons_dk.png" width="177" height="25" alt="" />
						<br />
						<br />
						<br />
						<b>LYD:</b> Du kan justere på lyden ved at trykke på{' '}
						<img src="images/help/sound_dk.png" width="47" height="10" alt="" />. Her kan du vælge, om du kun vil høre
						lyde fra hundene (Lyd FX), baggrundslyden (atmosfære) eller begge dele. Du kan også slå lyden helt fra.
						<br />
						<br />
						<b>SØG:</b> Denne knap kan du bruge til at finde andre hundes snuseprofiler, som ikke er i samme rum som
						dig. Tryk på <img src="images/help/search_dk.png" width="47" height="10" alt="" />. skriv hundenavnet og
						vælg «Sniff».
						<br />
						<br />
						<b>INDSTILLINGER:</b> Ved <img src="images/help/options_dk.png" width="47" height="10" alt="" /> finder du
						diverse indstillinger.
						<br />
						<b>Gennemsigtig tekstboble:</b> Er der en hund, der skriver beskeder, der er i vejen? Tag din mus hen over
						tekstboblen, så den bliver gennemsigtig. <br />
						<br />
						<b>Markeret tekstboble:</b> Hvis der er en anden hund i rummet, som siger dit hundenavn, vil deres
						tekstboble få en lyserød kant. Du kan vælge at slå denne indstilling til eller fra. <br />
						<br />
						<b>Automatisk koma:</b> Skal din hund automatisk lægge sig i koma, hvis du ikke er til stede et øjeblik? Sæt
						et ”grønt hak” ved denne.
						<br />
						<br />
						<b>Skjul slip/giv-funktion:</b> Bange for at give en hat væk ved et uheld? Eller slippe en hat på jorden,
						selvom du ikke mente at gøre det? Tryk på denne for at få slip/giv-knappen væk i menuen.
						<br />
						<br />
						<b>Dynamisk skalering:</b> Hvis du spiller med et andet zoom end 100 % eller 200 %, så kan du krydse af på
						dynamisk skalering for ikke at få halve pixels. <br />
						<br />
						<b>ANMELD:</b> Hvis du oplever, at en eller flere hunde bryder reglerne i Hundeparken, kan du anmelde dem.
						Tryk på <img src="images/help/report_dk.png" width="47" height="10" alt="" />, og vælg den rigtige kategori.
						Ønsker du at forklare situationen eller nævne væsentlige detaljer, kan du skrive det i tekstbeskeden
						nedenunder. Bagefter vil anmeldelsen havne hos Hundeparkens moderatorer, som får beskeden med det samme.
						Useriøse anmeldelser vil ikke blive håndteret.
					</Fragment>
				),
				no: (
					<Fragment>
						Nederst til venstre i parken er det en del forskjellige knapper:
						<br />
						<br />
						<img src="images/help/buttons_no.png" width="177" height="25" alt="" />
						<br />
						<br />
						<br />
						<b>LYD:</b> Du kan skru av/på lyden ved å trykke på{' '}
						<img src="images/help/sound_no.png" width="47" height="10" alt="" />. Her kan du velge om du vil skru av kun
						bjeff (Lyd FX) eller bakgrunnslyd (Atmosfære).
						<br />
						<br />
						<b>SØK:</b> Søk brukes til å finne snuseprofilen til en hund som ikke er i rommet. Trykk på{' '}
						<img src="images/help/search_no.png" width="47" height="10" alt="" />. Skriv inn hundenavnet, og velg
						«Sniff».
						<br />
						<br />
						<b>OPPSETT:</b> <img src="images/help/options_no.png" width="47" height="10" alt="" /> Her finner du diverse
						innstillinger.
						<br />
						<b>Gjennomsiktig boble:</b> Skriver en hund lange irriterende meldinger? Ta musepekeren over boblen for at
						den skal bli gjennomsiktig. <br />
						<br />
						<b>Markert boble:</b> Hvis noen sier hundenavnet ditt i parken vil snakkeboblen deres markeres med en rosa
						kant. Dette kan du velge om du vil skru på eller av. <br />
						<br />
						<b>Auto koma:</b> Ønsker du at hunden din skal legge seg automatisk i koma hvis du ikke er aktiv på en
						stund? Sett grønn hake på denne.
						<br />
						<br />
						<b>Skjul slipp/gi funksjon:</b> Redd for å gi bort en hatt ved et uhell? Eller slippe en hatt på bakken uten
						at du mente det? Trykk på denne for å få bort slipp/gi knappen i menyen.
						<br />
						<br />
						<b>Dynamisk skalering:</b> Hvis du spiller på en annen zoom % enn 100 eller 200, kan du hake av på Dynamisk
						skalering for å ikke få halve pixler.
						<br />
						<br />
						<b>ANMELD:</b> Hvis du opplever at noen bryter reglene, kan du anmelde hunden. Trykk på{' '}
						<img src="images/help/report_no.png" width="47" height="10" alt="" />, og huk av for riktig kategori. Dersom
						du ønsker å forklare situasjonen, eller detaljer du mener er vesentlig, kan du skrive i tekstboksen under.
						Anmeldelsen vil havne hos en moderator som får beskjed med en gang. Useriøse anmeldelser vil ikke bli
						håndtert.
					</Fragment>
				),
				se: (
					<Fragment>
						Längst ner till vänster i parken finns det en del olika knappar:
						<br />
						<br />
						<img src="images/help/buttons_se.png" width="177" height="25" alt="" />
						<br />
						<br />
						<br />
						<b>LJUD:</b> Du kan stänga av/på ljudet genom att trycka på{' '}
						<img src="images/help/sound_se.png" width="47" height="10" alt="" />. Här kan du välja om du vill stänga av
						skällanden (Ljud FX) eller bakgrundsljud (Atmosfär).
						<br />
						<br />
						<b>SÖK:</b> Sök används för att hitta profilsidan till en hund som inte är i rummet. Tryck på{' '}
						<img src="images/help/search_se.png" width="47" height="10" alt="" />. skriv in hundnamnet och välj «Sniff».
						<br />
						<br />
						<b>OPTIONS:</b> <img src="images/help/options_se.png" width="47" height="10" alt="" /> Här hittar du olika
						inställningar.
						<br />
						<b>Transparent bubbla:</b> Skriver en hund långa irriterande meddelanden? Håll muspekaren över bubblan för
						att den ska bli transparent. <br />
						<br />
						<b>Markerad bubbla:</b> Om någon säger ditt hundnamn i parken kommer deras chattbubbla markeras med en rosa
						kant. Detta kan du välja om du vill sätta på eller stänga av. <br />
						<br />
						<b>Auto koma:</b> Önskar du att din hund automatiskt ska lägga sig i koma ifall du inte är aktiv på en
						stund? Bocka i denna ruta.
						<br />
						<br />
						<b>Dölj släpp/ge-funktion:</b> Rädd för att råka ge bort en hatt? Eller släppa en hatt på marken, fast det
						inte var meningen? Tryck på denna för att ta bort släpp/ge-knappen från menyn. <br />
						<br /> <b>Dynamisk skalning:</b> Om du spelar med en annan zoom än 100 % eller 200%, så kan du kryssa ur på
						dynamisk skalning för att inte få halva pixels.
						<br />
						<br />
						<b>ANMÄL:</b> Om du upplever att någon bryter mot reglerna kan du anmäla den hunden. Tryck på{' '}
						<img src="images/help/report_se.png" width="47" height="10" alt="" />, och kryssa i rätt kategori. Ifall du
						önskar förklara situationen eller vill dela detaljer du tycker är viktiga, kan du skriva i textrutan under.
						Anmälan kommer hamna hos en moderator som får besked om din anmälan på en gång. Oseriösa anmälningar kommer
						inte bli hanterade.
					</Fragment>
				),
				en: (
					<Fragment>
						In the bottom left you'll find a few buttons:
						<br />
						<br />
						<img src="images/help/buttons_en.png" width="177" height="25" alt="" />
						<br />
						<br />
						<br />
						<b>SOUND:</b> You can turn off the sound effects by pushing{' '}
						<img src="images/help/sound_en.png" width="47" height="10" alt="" />. Here you can choose between only
						turning off the sound of barking (Sound FX) or background sound (Atmosphere).
						<br />
						<br />
						<b>SEARCH:</b> Search is used to find the sniffing profile of a dog that’s currently not present in the
						room. Push <img src="images/help/search_en.png" width="47" height="10" alt="" />. Type in the dog's name,
						and push «Sniff».
						<br />
						<br />
						<b>OPTIONS:</b> <img src="images/help/options_en.png" width="47" height="10" alt="" /> Here you’ll find
						different options for the game. <br />
						<b>Transparent bubble:</b> Is a dog writing long, annoying messages? Take the cursor over the chat-bubble to
						make it transparent.
						<br />
						<br />
						<b>Outlined bubble:</b> If someone mentions your dog's name in the park, their chat-bubble will be outlined
						with a pink glow. This can be turned on and off.
						<br />
						<br />
						<b>Auto coma:</b> Do you wish for your dog to automatically be put in a coma if you’ve been inactive for a
						while? Then check this option with a green checkmark. <br />
						<br /> <b>Hide drop/give function:</b> Afraid of transferring a hat by accident? Or dropping a hat
						unintentionally? Tick the box called “hide drop/give function” in options to hide them from the drop-down
						menu.
						<br />
						<br /> <b>Dynamic scaling:</b> If you prefer to play with a zoom size other than 100% or 200% without seeing
						split pixels, mark the box called «dynamic scaling» in options.
						<br />
						<br />
						<b>REPORT:</b> If you experience that someone is breaking the rules, then you can report the dog. Click{' '}
						<img src="images/help/report_en.png" width="47" height="10" alt="" />, and check the correct category. If
						you wish to explain the situation, or deem certain details important, you can write in the text-box below.
						The report will be forwarded to a moderator immediately. Only serious reports will be considered.
					</Fragment>
				),
			}),
		},
	];

	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					color="#ff8400"
					title={inlineTranslation({
						dk: 'Hjælp og svar på spørgsmål',
						no: 'Hjelp og svar på spørgsmål',
						se: 'Hjälp och svar på frågor',
						en: 'Questions and answers',
					})}
					icon="/images/help_page.png"
					sections={[
						{
							content: (
								<p>
									<b>{getTranslation('TEAM_MENU_TITLE')}</b>
									<div className={styles.menu}>
										{sections.map(section => {
											return (
												<Link to={`#${section.title}`}>
													{'>'} {section.title}
												</Link>
											);
										})}
									</div>
								</p>
							),
						},
						...sections.map(section => ({
							...section,
							id: section.title,
							title: (
								<Fragment>
									{section.title} - <p className={helpStyles.title}>{section.subTitle}</p>
								</Fragment>
							),
							content: (
								<div className={helpStyles.section}>
									{section.image && <img src={section.image} alt={section.title} />}
									<div className={helpStyles.content}>{section.content}</div>
								</div>
							),
						})),
					]}
				/>
			</div>
		</div>
	);
};
