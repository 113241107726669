import 'react-app-polyfill/stable';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { Router } from './Router';
import { setContext } from '@apollo/client/link/context';
import { LanguageProvider } from './components/Language/LanguageContext';
import { API_URL, AUTH_TOKEN_NAME } from './constants';

const httpLink = createHttpLink({
	uri: API_URL,
	credentials: 'include',
	fetchOptions: {
		mode: 'cors',
	},
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem(AUTH_TOKEN_NAME);
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${encodeURIComponent(token)}` : '',
		},
	};
});

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<LanguageProvider>
				<Router />
			</LanguageProvider>
		</ApolloProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
