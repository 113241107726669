import { FunctionComponent } from 'react';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { Layout } from './components';
import {
	AgePage,
	AnnouncementPage,
	ChangeUsernamePage,
	ChoosePage,
	ClientPage,
	CreditsPage,
	DogColorPickerPage,
	FkWantedPage,
	FrontPage,
	HelpPage,
	HighscorePage,
	KobbelPage,
	NewDogPage,
	NewUserPage,
	RangPage,
	RulesPage,
	TeamPage,
} from './pages';
import { APP_URL } from './paths';

const router = createBrowserRouter(
	[
		{
			path: '/',
			element: <Layout />,
			//   loader: rootLoader,
			children: [
				{
					path: '/',
					element: <FrontPage />,
					//   loader: teamLoader,
				},
				{
					path: 'rules',
					element: <RulesPage />,
					//   loader: teamLoader,
				},

				{
					path: 'team',
					element: <TeamPage />,
				},

				{
					path: '/credits',
					element: <CreditsPage />,
				},

				{
					path: '/highscore',
					element: <HighscorePage />,
				},
				{
					path: '/highscore/:game',
					element: <HighscorePage />,
				},

				{
					path: '/help',
					element: <HelpPage />,
				},

				{
					path: '/announcement/:id',
					element: <AnnouncementPage />,
				},

				{
					path: '/fk-wanted',
					element: <FkWantedPage />,
				},
			],
		},
		{
			path: '/h5',
			children: [
				{
					path: 'choose.php',
					element: <ChoosePage />,
				},
				{
					path: 'choose',
					element: <ChoosePage />,
				},

				{
					path: 'client',
					element: <ClientPage />,
				},

				{
					path: 'new-user',
					element: <NewUserPage />,
				},

				{
					path: 'change-username',
					element: <ChangeUsernamePage />,
				},

				{
					path: 'choose/new',
					element: <NewDogPage />,
				},
				{
					path: 'choose/new/:id',
					element: <NewDogPage />,
				},
				{
					path: 'choose/color/:id',
					element: <DogColorPickerPage />,
				},
				{
					path: '',
					element: <Navigate to="/" replace />,
				},
			],
		},
		{
			path: '/whatis',
			children: [
				{
					path: 'age',
					element: <AgePage />,
				},
				{
					path: 'rang',
					element: <RangPage />,
				},
				{
					path: 'kobbel',
					element: <KobbelPage />,
				},
			],
		},
	],
	{ basename: APP_URL }
);

export const Router: FunctionComponent = () => <RouterProvider router={router} />;
